import React, { ChangeEvent, useRef, useState } from 'react';
import AmericanExpressLogo from '../../assets/icons/AmericanExpressLogo.svg';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import CloseBtnBlackIcon from '../../assets/icons/CloseBtnBlackIcon.svg';
import ErrorIconRed from '../../assets/icons/ErrorIconRed.svg';
import FrFlagCircle from '../../assets/icons/FrFlagCircle.svg';
import HidePwIcon from '../../assets/icons/HidePwIcon.svg';
import MastercardLogo from '../../assets/icons/MastercardLogo.svg';
import SearchIconGrey from '../../assets/icons/SearchIconGrey.svg';
import ShowPwIcon from '../../assets/icons/ShowPwIcon.svg';
import UploadIconWhite from '../../assets/icons/UploadIconWhite.svg';
import VisaLogo from '../../assets/icons/VisaLogo.svg';
import Globe from '../../assets/icons/globe.svg';
import infoIcon from '../../assets/icons/infoIcon.svg';
import { isValidDate } from '../../utils/DateFormatter';
import getCurrentDate from '../../utils/GetCurrentDate';
import CheckboxInput from './CheckboxInput';
import LabelInput from './LabelInput';
import SkeletonLoader from '../SkeletonLoader';

/**
 *   component
 **/

interface InputFieldProps {
  type?: 'text' | 'password' | 'number' | 'email' | 'tel' | 'date';
  placeholder?: string;
  label?: string;
  icon?: string;
  value?: any;

  style?:
    | 'auth'
    | 'search'
    | 'form'
    | 'inpflag'
    | 'inpdate'
    | 'selectInp'
    | 'checkbox'
    | 'counter'
    | 'card'
    | 'upload'
    | 'switcher'
    | 'prefix'
    | 'filter';
  className?: string;
  minLength?: number;
  maxLength?: number;
  radius?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  SelectType?: 'flags' | 'search';
  ischecked?: boolean;
  name?: string;
  id?: string;
  isRequired?: boolean;
  isPrincipal?: boolean;
  initialCounter?: number;
  prefix?: string;
  haserror?: boolean;
  errorMsg?: string;
  onClick?: () => void;
  onClear?: () => void;
  onKeyUp?: () => any;
  onKeyDown?: (e: any) => any;
  onClickClear?: () => void;
  cardType?: string;
  maxLengthCardNumber?: number;
  min?: number;
  max?: number;
  info?: string;
  infoTitle?: string;
  date?: boolean;
  defaultValue?: string | number;
  uploadText?: string;
  checked?: boolean;
  month?: number;
  year?: number;
  labelCvc?: string;
  cvc?: number;
  siren?: boolean;
  onFocus?: any;
  allowOnlyLetters?: boolean;
  isInputBlocked?: boolean;
  onchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  hideIconPw?: boolean;
  showSkeleton?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  type = 'text',
  placeholder,
  label,
  icon,
  value,
  style,
  className,
  radius,
  onChange,
  onClear,
  onClickClear,
  isDisabled,
  SelectType,
  ischecked,
  name,
  minLength,
  maxLength,
  id,
  isPrincipal,
  prefix,
  haserror,
  errorMsg,
  onClick,
  onKeyUp,
  isRequired,
  cardType,
  maxLengthCardNumber = 16,
  min,
  max,
  info,
  infoTitle,
  uploadText,
  defaultValue,
  checked,
  month,
  year,
  labelCvc,
  cvc,
  siren,
  onFocus,
  allowOnlyLetters,
  isInputBlocked,
  onBlur,
  hideIconPw,
  showSkeleton,
  onKeyDown
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [txt, setTxt] = useState('');
  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const cardImg = () => {
    switch (cardType) {
      case 'express':
        return AmericanExpressLogo;
      case 'master':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      default:
        return Globe;
    }
  };
  const handleKeyUp = () => {
    if (inputRef.current) {
      const inputValue = inputRef.current.value;
      const currentDate = new Date(getCurrentDate());

      const inputDate = isValidDate(inputValue)
        ? new Date(inputValue)
        : currentDate;

      const validDate = inputDate > currentDate ? currentDate : inputDate;
      inputRef.current.value = validDate.toISOString().split('T')[0];
    }
  };
  // const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   alert("")
  //   const { value } = e.target;
  //   console.log('Input value: ', value);

  //   const re = /^[A-Za-z]+$/;
  //   if (value === "" || re.test(value)) {
  //     setTxt(value);
  //   }
  // };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > maxLengthCardNumber) {
      e.target.value = value.substring(0, maxLengthCardNumber);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const renderContent = () => {
    switch (style) {
      case 'auth':
        return (
          <div className="inputField fl_col gp10">
            <div className="inp-container">
              {label && <label>{label}</label>}
              {icon && <img className="icon" src={icon} alt="" />}
              <div className="inp">
                <input
                  autoComplete="off"
                  className={`${className ? className : ''} ${radius ? radius : ''} ${type === 'email' ? 'email-field' : ''}`}
                  type={
                    type === 'password'
                      ? showPassword
                        ? 'text'
                        : 'password'
                      : type
                  }
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  onFocus={onFocus}
                  minLength={minLength}
                  maxLength={maxLength}
                  value={value}
                  onKeyUp={onKeyUp}
                  required={isRequired}
                />
                {haserror && (
                  <img className="error-img" src={ErrorIconRed} alt="" />
                )}
              </div>

              {type === 'password' && (
                <img
                  src={showPassword ? ShowPwIcon : HidePwIcon}
                  alt={showPassword ? 'Hide' : 'Show'}
                  className="show-hide-pw"
                  onClick={handlePasswordVisibilityToggle}
                />
              )}

              {onClickClear && (
                <img
                  className="clear-field"
                  width={20}
                  src={CloseBtnBlackIcon}
                  alt=""
                  onClick={onClickClear}
                />
              )}
            </div>
            {haserror && <p className="p12 errorMsg">{errorMsg}</p>}
          </div>
        );

      case 'search':
        return (
          <div>
            <div className="inp">
              <input
                autoComplete="off"
                name={name}
                className={`search-input p14 ${radius ? radius : ''}`}
                type="search"
                placeholder={placeholder}
                onChange={onChange}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && (
                <img className="error-img" src={ErrorIconRed} alt="" />
              )}
            </div>
            {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
            {icon && <img className="icon" src={icon} alt="" />}
          </div>
        );

      case 'form':
        return (
          <div className="fl_col gp8">
            {label && (
              <div className="fl_row gp26 inp-header">
                <LabelInput label={label} />
                {info && (
                  <div className="info-tooltip">
                    <img src={infoIcon} alt="" />
                    <div className="infoBox fl_col gp4">
                      {infoTitle && <p className="p14">{infoTitle}</p>}
                      {info && <p className="p12">{info}</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="inp">
              <SkeletonLoader type="input" show={showSkeleton} />
              <input
                autoComplete="off"
                readOnly={isInputBlocked}
                type={type}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius ? radius : 'rd8'}`}
                disabled={isDisabled}
                minLength={minLength}
                maxLength={maxLength}
                onClick={onClick}
                onKeyPress={(e) => {
                  if (type === 'number') {
                    const char = String.fromCharCode(e.which);
                    const value = (e.target as HTMLInputElement).value;
                    if (
                      !/[\d.]/.test(char) ||
                      (char === '.' && value.includes('.'))
                    ) {
                      e.preventDefault();
                    }
                  }
                  if (
                    siren === true &&
                    (e.target as HTMLInputElement).value.length >= 9
                  ) {
                    e.preventDefault();
                  }
                  if (allowOnlyLetters) {
                    const char = String.fromCharCode(e.which);
                    const value = (e.target as HTMLInputElement).value;
                    // Allow letters (A-Z, a-z) and space
                    if (!/[A-Za-z\s]/.test(char) || (char === '.' && value.includes('.'))) {
                      e.preventDefault();
                    }
                  }
                }}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
                onKeyDown={onKeyDown}
                min={min}
                max={max}
                defaultValue={defaultValue}
              />

              {haserror && (
                <img
                  className="error-img"
                  src={ErrorIconRed}
                  alt="ErrorIconRed"
                />
              )}
            </div>
            {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
          </div>
        );
      case 'inpflag':
        return (
          <div className="fl_col gp8">
            {label && (
              <div className="fl_row gp26 inp-header">
                <LabelInput label={label} />
                {info && (
                  <div className="info-tooltip">
                    <img src={infoIcon} alt="" />
                    <div className="infoBox fl_col gp4">
                      {infoTitle && <p className="p14">{infoTitle}</p>}
                      {info && <p className="p12">{info}</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="inp">
              <SkeletonLoader show type="input" />
              <input
                autoComplete="off"
                name={name}
                type={type}
                onChange={onChange}
                placeholder={placeholder}
                className={`p14 ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                // onKeyPress={(e) => {
                //   const allowedKeys = /^[0-9]*$/;
                //   if (!allowedKeys.test(e.key)) {
                //     e.preventDefault();
                //   }
                // }}
                step="1"
                min="0"
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && (
                <img className="error-img" src={ErrorIconRed} alt="" />
              )}
            </div>
            {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
            <div className="flagdropdown fl_row gp8">
              <img className="flag" src={FrFlagCircle} alt="" />
              <img className="arrow" src={BottomArrowGreyIcon} alt="" />
            </div>
          </div>
        );

      case 'inpdate':
        return (
          <div className="fl_col gp8">
            {label && (
              <div className="fl_row gp26 inp-header">
                <LabelInput label={label} />
                {info && (
                  <div className="info-tooltip">
                    <img src={infoIcon} alt="" />
                    <div className="infoBox fl_col gp4">
                      {infoTitle && <p className="p14">{infoTitle}</p>}
                      {info && <p className="p12">{info}</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="inp">
              <input
                autoComplete="off"
                ref={inputRef}
                name={name}
                type="date"
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                min="0"
                max={getCurrentDate()}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={handleKeyUp}
                required={isRequired}
                onChange={onChange}
              />
              <span
                className={`display-date p14 pd14-r-l ${isDisabled ? 'disabled-date' : ''}`}
              >
                {value}
              </span>
              {haserror && (
                <img className="error-img" src={ErrorIconRed} alt="" />
              )}
            </div>
            {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
          </div>
        );
      case 'selectInp':
        return (
          <div className="fl_col gp8">
            {label && (
              <div className="fl_row gp26 inp-header">
                <LabelInput label={label} />
                {info && (
                  <div className="info-tooltip">
                    <img src={infoIcon} alt="" />
                    <div className="infoBox fl_col gp4">
                      {infoTitle && <p className="p14">{infoTitle}</p>}
                      {info && <p className="p12">{info}</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <CheckboxInput
            label={label}
            name={name}
            id={id}
            className={className}
            ischecked={ischecked}
            isPrincipal={isPrincipal}
          />
        );
      case 'card':
        return (
          <div className="fl_col gp8">
            {label && (
              <div className="fl_row gp26 inp-header">
                <LabelInput label={label} />
                {info && (
                  <div className="info-tooltip">
                    <img src={infoIcon} alt="" />
                    <div className="infoBox fl_col gp4">
                      {infoTitle && <p className="p14">{infoTitle}</p>}
                      {info && <p className="p12">{info}</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="inp">
              <div className="card-img">
                <img src={cardImg()} alt="Card Icon" />
              </div>
              <input
                autoComplete="off"
                name={name}
                type="number"
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                min="0"
                minLength={minLength}
                maxLength={maxLengthCardNumber}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
                onChange={handleInputChange}
              />
              {haserror && (
                <img className="error-img" src={ErrorIconRed} alt="" />
              )}
            </div>
            {errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
          </div>
        );

      // case 'dateCard': {
      //   return (

      //   )
      // }
      case 'upload': {
        return (
          <div className="Upload fl_col gp8">
            <LabelInput label={label} />
            <div className="upload-field">
              <input
                autoComplete="off"
                type="file"
                name={name}
                id={id}
                value={value}
                required={isRequired}
                onChange={handleInputChange}
              />
              <div className="field-container">
                {uploadText && (
                  <p className="p12 AuroMetalSaurus-txt ">{uploadText}</p>
                )}
                <div className="btn-field fl_row gp6">
                  <img src={UploadIconWhite} alt="" />
                  <p className="p14 w-600">Ajouter</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case 'switcher':
        return (
          <div className="switcher-field fl_row gp16 ai_c">
            <div className="field-item fl_row">
              <input
                autoComplete="off"
                type="checkbox"
                id={id}
                onChange={onChange}
                value={value}
                checked={checked}
                name={name}
              />
              <label htmlFor={id}></label>
            </div>
            <LabelInput label={label} />
          </div>
        );
      case 'prefix':
        return (
          <div className="prefix-field fl_col gp16">
            <LabelInput label={label} />
            <div className="field-item fl_row ai_c">
              <input
                autoComplete="off"
                name={name}
                type="number"
                onChange={onChange}
                placeholder={placeholder}
                className={`pd14-r-l p14 ${isDisabled ? 'isDisabled' : ''}`}
                disabled={isDisabled}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
                onBlur={onBlur}
              />
              <div className="prefixValue">
                <p className="p14 ">{prefix}</p>
              </div>
              
            </div>
          </div>
        );
      case 'filter':
        return (
          <div className="filter-inp">
            <img src={SearchIconGrey} alt="" />
            <input
              autoComplete="off"
              value={value}
              name={name}
              type="text"
              onChange={onChange}
              placeholder={placeholder}
              // value={value}
              onKeyUp={onKeyUp}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`input-field ${isRequired ? 'isRequired' : ''} ${haserror ? 'haserror' : ''}`}
    >
      <div className={`inp-wrapper ${style} ${icon ? 'InpICon' : ''}`}>
        {renderContent()}
      </div>
    </div>
  );
};

export default InputField;
