import React, { useEffect, useRef, useState } from 'react';
import SelectInput from '../../../../components/Input/SelectInput';
import { useDispatch } from 'react-redux';
import etablissementApi from '../../../../services/apiHandlers/etablissementApi';
import ButtonElement from '../../../../components/button/button';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';
import IconErrorRed from '../../../../assets/icons/IconErrorRed.svg';

export default function ChooseEtablissement({
  onConfirm,
}: {
  onConfirm?: () => void;
}) {
  const dispatch = useDispatch();

  const [idEtab, setidEtab] = useState<number | undefined>();
  const [idEntr, setidEntr] = useState<number | undefined>();
  const [companies_list, setcompanies_list] = useState<any[]>([]);
  const [Etablist, setEtablist] = useState([]);
  const [showEntrepriseError, setShowEntrepriseError] = useState(false);
  const [showEtablissementError, setShowEtablissementError] = useState(false);

  const fetchEtablissementslist = async () => {
   localStorage.setItem('idCompany', '' + idEntr);

    const response = await catalogueApi.getListPv(idEntr);
    setEtablist(response?.data?.data?.result);
  };

  const fetchCompanies = async () => {
    const response = await catalogueApi.getListCompanies();
    let data = response.data?.data?.result;
    setcompanies_list(data);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (idEntr) {
      setEtablist([]);
      fetchEtablissementslist();
      setShowEntrepriseError(false);
    }
  }, [idEntr]);

  useEffect(() => {
    if (idEtab) {
      localStorage.setItem('idEtablishement', idEtab + '');

      setShowEtablissementError(false);
    }
  }, [idEtab]);

  const handleButtonClick = () => {
    if (!idEntr) setShowEntrepriseError(true);
    if (!idEtab) setShowEtablissementError(true);
    if (idEntr && idEtab && onConfirm) onConfirm();
  };

  return (
    <div className="fl_col gp16">
      <div className="fl_col gp16">
        <div className="fl_col gp8">
          <SelectInput
            options={companies_list?.map((_) => _.nom)}
            placeholder={'Sélectionner une entreprise'}
            isRequired={false}
            label={'Entreprise'}
            name="entreprise"
            target="reference"
            setidEntr={setidEntr}
            onSelect={(selectValue) => {
              setidEntr(companies_list?.find((_) => _?.nom == selectValue)?.id);
              localStorage.setItem('EntrNameEtablishement', selectValue + '');
            }}
          />
          {showEntrepriseError && (
            <div className="fl_row ai_c gp4 pd4-r-l">
              <img src={IconErrorRed} alt="" />
              <p className="p14 Independence-txt w-300">
                Veuillez choisir une entreprise.
              </p>
            </div>
          )}
        </div>
        {idEntr && (
          <div className="fl_col gp8">
            <SelectInput
              defaultValue={
                (Etablist?.find((_: any) => _?.id == idEtab) as any)?.nom ?? ''
              }
              options={Etablist?.map((_: any) => _?.nom)}
              placeholder={'Sélectionner un établissement'}
              isRequired={false}
              label={'Établissement'}
              name="établissement"
              target="reference"
              setidEtab={setidEtab}
              onSelect={(selectValue) => {
                setidEtab(
                  (Etablist?.find((_: any) => _?.nom == selectValue) as any)
                    ?.id,
                );
                localStorage.setItem('EtabNameEtablishement', selectValue + '');
              }}
            />
            {showEtablissementError && (
              <div className="fl_row ai_c gp4 pd4-r-l">
                <img src={IconErrorRed} alt="" />
                <p className="p14 Independence-txt w-300">
                  Veuillez choisir un établissement.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <ButtonElement
        className="w-full"
        type="TextButton"
        textSize="p18"
        text="Accéder"
        textColor="white-txt"
        buttonSize="full"
        radius="rd12"
        onClick={handleButtonClick}
        buttonStyle="btn-primary"
      ></ButtonElement>
    </div>
  );
}
