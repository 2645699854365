import React from 'react';
import ButtonElement from '../../../components/button/button';
import CartCheckboxChildElement from '../../../components/Carts/CartCheckboxChildElement';
import CartCheckboxParentElement from '../../../components/Carts/CartCheckboxParentElement';
import { languageData } from '../../../i18n';
import CloseBtnPrimary from '../../../assets/icons/CloseBtnPrimary.svg';
import InputField from '../../../components/Input/Input';
import CheckBox from '../../../components/Catalogue/Checkbox';
import SwitcherCheckbox from '../../../components/Catalogue/SwitcherCheckbox';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface ModalAddPointFideliteProps {
  onClickSave?: () => void;
  onClickClose?: () => void;
  isActivated?: boolean;
  onChange?: (e: any) => void;
  formData?: any;
  errors?: any;
  onKeyDown?: (e: any) => any;
}

const ModalAddPointFidelite: React.FC<ModalAddPointFideliteProps> = ({
  onClickSave,
  onClickClose,
  isActivated,
  onChange,
  formData,
  errors,
  onKeyDown,
}) => {
  return (
    <div className="ModalAddPointFidelite fl_col gp40">
      <div className="fl_row ai_c gp40">
        <p className="p20 txt-nowrap w-600">Ajouter régle de fidélité</p>
        <div className="fl_row ai_c gp20">
          {/* <InputField
            style="switcher"
            name="isActive"
            checked={formData.isActive || false}
            onChange={onChange}
          /> */}
          <SwitcherCheckbox
            ischecked={formData.isActive || false}
            onChange={onChange}
            label={'Statut'}
            name="isActive"
          />
        </div>
      </div>
      <div className="fl_col gp10">
        <div className="fl_row gp10">
          <InputField
            placeholder={'Désignation'}
            isRequired={true}
            name="designation"
            value={formData.designation || ''}
            type="text"
            label={'Désignation'}
            style="form"
            isDisabled={false}
            onChange={onChange}
            haserror={errors?.designation ? true : false}
            errorMsg={errors?.designation}
          />
          <InputField
            placeholder={'Montant de conversion  (€)'}
            isRequired={true}
            name="conversionAmount"
            value={formData.conversionAmount || ''}
            type="number"
            label={'Montant de conversion  (€)'}
            style="form"
            isDisabled={false}
            onChange={onChange}
            haserror={errors?.conversionAmount ? true : false}
            errorMsg={errors?.conversionAmount}
          />
        </div>
        <div className="fl_row">
          <InputField
            placeholder={'Nombre de points à gagner'}
            isRequired={true}
            name="loyaltyPointsAssociated"
            value={formData.loyaltyPointsAssociated || ''}
            type="number"
            label={'Nombre de points à gagner'}
            style="form"
            isDisabled={false}
            onChange={onChange}
            onKeyDown={(e: any) => onKeyDown && onKeyDown(e)}
            haserror={errors?.loyaltyPointsAssociated ? true : false}
            errorMsg={errors?.loyaltyPointsAssociated}
          />
        </div>
      </div>
      <div className="ModalFooter">
        <div className="wrapperFooter">
          <div className="fl_row gp12">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text={languageData.fr.Modal.cancel}
                buttonSize={'small'}
                onClick={onClickClose}
              />
            </div>
            <div className="fl-3">
              <ButtonElement
                type={'TextButton'}
                text="Ajouter"
                buttonSize={'small'}
                buttonStyle="btn-primary"
                onClick={onClickSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddPointFidelite;
