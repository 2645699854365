interface DaySelectorProps {
  day: string;
  dayIndex: number;
  isSelected: boolean;
  onChange: (dayIndex: number) => void;
  showNextDay: boolean;
  endTime: string;
}

export const DaySelector: React.FC<DaySelectorProps> = ({
  day,
  dayIndex,
  isSelected,
  onChange,
  showNextDay,
  endTime,
}) => {
  const id = `${day.toLowerCase()}-checkbox-${dayIndex}`;

  return (
    <div className="date-row fl-1">
      <input
        type="checkbox"
        checked={isSelected}
        name="lundi"
        onChange={() => onChange(dayIndex + 1)}
        id={id}
      />
      <div className="day-name">
        <label htmlFor={id} className="date-name p18 w-500">
          {day}
        </label>
      </div>

      {isSelected && showNextDay && (
        <span className="next-day p12 FunGreen-txt w-700">
          Jusqu'à {endTime}
        </span>
      )}
    </div>
  );
};
