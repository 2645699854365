import * as actionTypes from '../../actions/catalogues/actionTypes';

const initialState = {
    error: null,
    loading: false,
    Menu: false,
  };
  
  const MenuReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case actionTypes.START_GET_MENUS_STOCK:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case actionTypes.SUCCESS_GET_MENUS_STOCK:
        return {
          ...state,
          loading: false,
          Menu: action.payload,
        };
      case actionTypes.FAIL_GET_MENUS_STOCK: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
      default:
        return state;
    }
  };

  export default MenuReducer;