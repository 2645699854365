import {
  Category,
  CategoryPayLoad,
} from './../../../models/services/module.category.d';
import { AxiosError } from 'axios';
import * as actionTypes from './actionTypes';
import {
  Article,
  ArticleAllergen,
  ArticleSaved,
  Badges,
  Composition,
  Family,
  GeneralCodeTva,
  Image,
  Level,
  LevelArticle,
  Menu,
  MenuObjectPayload,
  MenuPayload,
  SubFamily,
  Tags,
  UploadFolder,
  addArticlePayload,
  division,
  getArticleParams,
  level,
  levelPayload,
  purshaseMethod,
  screen,
} from '../../../models/services/module.catalogue';
import { CategorySingle } from '../../../models/services/module.category';
import {
  CardByID,
  CardPostSuccess,
  Channel,
  carteConfig,
  filtredPvConfig,
  modalData,
} from '../../../models/services/module.cart';

/*
 * +--------------------------+
 * | BADGES section           |
 * +--------------------------+
 */
export const getBadges = () => {
  return {
    type: actionTypes.START_GET_BADGES,
  };
};
export const getBadgesSuccess = (badges: Badges[]) => {
  return {
    type: actionTypes.SUCCESS_GET_BADGES,
    badges,
  };
};
export const getBadgesFail = (error: AxiosError) => {
  return {
    type: actionTypes.FAIL_GET_BADGES,
    error,
  };
};
export const postBadge = (badge: Badges, _?: () => void) => {
  return {
    type: actionTypes.POST_BADGE,
    badge,
    _,
  };
};
export const postBadgESuccess = () => {
  return {
    type: actionTypes.POST_BADGE_SUCCESS,
  };
};

/*
 * +--------------------------+
 * | ALLERGEN section         |
 * +--------------------------+
 */
export const getAllergen = (_?: (__?: ArticleAllergen[]) => void) => {
  return {
    type: actionTypes.START_GET_ALLERGEN,
    _,
  };
};
export const getAllergenSuccess = (allergens: ArticleAllergen[]) => {
  return {
    type: actionTypes.SUCCESS_GET_ALLERGEN,
    allergens,
  };
};
export const getAllergenFail = (error: AxiosError) => {
  return {
    type: actionTypes.FAIL_GET_ALLERGEN,
    error,
  };
};
export const postAllergen = (allergen: ArticleAllergen, _: () => void) => {
  return {
    type: actionTypes.POST_ALLERGEN,
    allergen,
    _,
  };
};
export const deleteAllergen = (allergen: ArticleAllergen, _: () => void) => {
  return {
    type: actionTypes.DELETE_ALLERGEN,
    allergen,
    _,
  };
};
export const postAllergenSuccess = () => {
  return {
    type: actionTypes.POST_ALLERGEN_SUCCESS,
  };
};
export const postAllergenFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_ALLERGEN_FAIL,
    error,
  };
};
/*
 * +--------------------------+
 * | FAMILY section           |
 * +--------------------------+
 */

export const getFamily = (_?: (__: Family[]) => void) => {
  return {
    type: actionTypes.START_GET_FAMILY,
    _,
  };
};

export const getFamilySuccess = (families: Family[]) => {
  return {
    type: actionTypes.SUCCESS_GET_FAMILY,
    families,
  };
};

export const getFamilyFail = (error: AxiosError) => {
  return {
    type: actionTypes.FAIL_GET_FAMILY,
    error,
  };
};

export const postFamily = (family: Family, _: () => void) => {
  return {
    type: actionTypes.POST_FAMILY,
    family,
    _,
  };
};

export const deleteFamily = (
  id: number,
  typeOfDelete: string,
  _: () => void,
) => {
  return {
    type: actionTypes.DELETE_FAMILY,
    id,
    typeOfDelete,
    _,
  };
};

export const postFamilySuccess = () => {
  return {
    type: actionTypes.POST_FAMILY_SUCCESS,
  };
};

export const postFamilyFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_FAMILY_FAIL,
    error,
  };
};
/*
 * +--------------------------+
 * | SUBFAMILY section        |
 * +--------------------------+
 */

export const getSubFamily = (id: number, _?: (__: SubFamily) => void) => {
  return {
    type: actionTypes.START_GET_SUBFAMILY,
    id,
    _,
  };
};

export const getSubFamilySuccess = (subFamilies: SubFamily) => {
  return {
    type: actionTypes.SUCCESS_GET_SUBFAMILY,
    subFamilies,
  };
};

export const getSubFamilyFail = (error: unknown) => {
  return {
    type: actionTypes.FAIL_GET_SUBFAMILY,
    error,
  };
};

export const postSubFamily = (subFamily: Family, _: () => void) => {
  return {
    type: actionTypes.POST_SUBFAMILY,
    subFamily,
    _,
  };
};

export const deleteSubFamily = (
  id: number,
  typeOfDelete: string,
  _: () => void,
) => {
  return {
    type: actionTypes.DELETE_SUBFAMILY,
    id,
    typeOfDelete,
    _,
  };
};

export const postSubFamilySuccess = () => {
  return {
    type: actionTypes.POST_SUBFAMILY_SUCCESS,
  };
};
export const postSubFamilyFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_SUBFAMILY_FAIL,
    error,
  };
};

/*
 * +--------------------------+
 * | TVA section              |
 * +--------------------------+
 */
export const getTva = (_?: (__: GeneralCodeTva[]) => void) => {
  return {
    type: actionTypes.START_GET_TVA,
    _,
  };
};
export const getTvaSuccess = (tvas: GeneralCodeTva[]) => {
  return {
    type: actionTypes.SUCCESS_GET_TVA,
    tvas,
  };
};
export const getTvaFail = (error: AxiosError) => {
  return {
    type: actionTypes.FAIL_GET_TVA,
    error,
  };
};
export const postTva = (tva: GeneralCodeTva, _: () => void) => {
  return {
    type: actionTypes.POST_TVA,
    tva,
    _,
  };
};
export const deleteTva = (tva: GeneralCodeTva, _: () => void) => {
  return {
    type: actionTypes.DELETE_TVA,
    tva,
    _,
  };
};
export const postTvaSuccess = () => {
  return {
    type: actionTypes.POST_TVA_SUCCESS,
  };
};
export const postTvaFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_TVA_FAIL,
    error,
  };
};

/*
 * +--------------------------+
 * | TAG section              |
 * +--------------------------+
 */
export const getTag = (_?: (_: Tags[]) => void) => {
  return {
    type: actionTypes.START_GET_TAG,
    _,
  };
};
export const getTagSuccess = (tags: Tags[]) => {
  return {
    type: actionTypes.SUCCESS_GET_TAG,
    tags,
  };
};
export const getTagFail = (error: AxiosError | unknown) => {
  return {
    type: actionTypes.FAIL_GET_TAG,
    error,
  };
};
export const postTag = (tag: Tags, _?: () => void) => {
  return {
    type: actionTypes.POST_TAG,
    tag,
    _,
  };
};
export const deleteTag = (tag: Tags, _?: () => void) => {
  return {
    type: actionTypes.DELETE_TAG,
    tag,
    _,
  };
};
export const postTagSuccess = () => {
  return {
    type: actionTypes.POST_TAG_SUCCESS,
  };
};
export const postTagFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_TAG_FAIL,
    error,
  };
};

/*
 * +--------------------------+
 * | ARTICLE section          |
 * +--------------------------+
 */
export const getArticle = (
  params: getArticleParams,
  _?: (__?: ArticleSaved[]) => void,
) => {
  return {
    type: actionTypes.START_GET_ARTICLE,
    params,
    _,
  };
};
export const getArticleWithId = (
  id: string,
  _?: (____?: {
    article?: Article;
    url?: string;
    allergenes?: ArticleAllergen[];
    ingredients?: Composition[];
  }) => void,
) => {
  return {
    type: actionTypes.START_GET_ARTICLE_ID,
    id,
    _,
  };
};
export const getArticleSuccess = (articles: ArticleSaved[]) => {
  return {
    type: actionTypes.SUCCESS_GET_ARTICLE,
    articles,
  };
};
export const getArticleFail = (error: AxiosError) => {
  return {
    type: actionTypes.FAIL_GET_ARTICLE,
    error,
  };
};
export const postArticle = (article: addArticlePayload, _?: () => void) => {
  return {
    type: actionTypes.POST_ARTICLE,
    article,
    _,
  };
};
export const postArticleSuccess = () => {
  return {
    type: actionTypes.POST_ARTICLE_SUCCESS,
  };
};
export const postArticleFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_ARTICLE_FAIL,
    error,
  };
};
export const deleteArticle = (id: string, _?: () => void) => {
  return {
    type: actionTypes.DELETE_ARTICLE,
    id,
    _,
  };
};
/*
 * +--------------------------+
 * | SEARCH VALUE section     |
 * +--------------------------+
 */
export const changeSearchValue = (value: string) => {
  return {
    type: actionTypes.CHANGE_SEARCH_FILED,
    value,
  };
};
export const clearSearchValue = () => {
  return {
    type: actionTypes.CLEAR_SEARCH_FILED,
  };
};

/*
 * +--------------------------+
 * | NEW MODAL SHOWN section  |
 * +--------------------------+
 */
export const setNewModalShown = (value: boolean) => {
  return {
    type: actionTypes.NEW_MODAL_SHOWN,
    value,
  };
};

/*
 * +--------------------------+
 * | TABLE COMPOSITION section|
 * +--------------------------+
 */
export const dragCompostion = (composition: Composition) => {
  return {
    type: actionTypes.DRAG_NEW_COMPOSITION,
    composition,
  };
};
export const unDragCompostion = (composition: Composition) => {
  return {
    type: actionTypes.unDRAG_NEW_COMPOSITION,
    composition,
  };
};
export const updateCompositions = (compositions: Partial<Composition>[]) => {
  return {
    type: actionTypes.UPDATE_COMPOSITIONS,
    compositions,
  };
};
/*
 * +--------------------------+
 * | UI  section|
 * +--------------------------+
 */
export const changeSidebarMode = (value: string) => {
  return {
    type: actionTypes.CHANGE_SIDE_BAR_MODE,
    value,
  };
};
export const updateCart = (value: boolean) => {
  return {
    type: actionTypes.CART_UPDATE,
    value,
  };
};
export const updateLoading = (value: boolean) => {
  return {
    type: actionTypes.UPDATE_LOADING,
    value,
  };
};
export const setColorPicker = (value: boolean) => {
  return {
    type: actionTypes.SET_COLOR_PICKER,
    value,
  };
};
export const reduceMainSiderBar = (value: boolean) => {
  return {
    type: actionTypes.REDUCE_MAIN_SIDE_BAR,
    value,
  };
};
export const changeSidebarMenuMode = (value: string) => {
  return {
    type: actionTypes.CHANGE_SIDE_BAR_MENU_MODE,
    value,
  };
};
/*
 * +--------------------------+
 * | TABLE MENU LEVEL section|
 * +--------------------------+
 */

export const dragMenuLevel = (level: level) => {
  return {
    type: actionTypes.DRAG_NEW_MENU_LEVEL,
    level,
  };
};
export const addEditSuccessMenu = () => {
  return {
    type: actionTypes.EDIT_SUCCESS_MENU_LEVELS,
  };
};
export const unDragMenuLevel = (level: level) => {
  return {
    type: actionTypes.unDRAG_NEW_MENU_LEVEL,
    level,
  };
};

export const updateMenuLevels = (menuLevels: level[]) => {
  return {
    type: actionTypes.UPDATE_MENU_LEVELS,
    menuLevels,
  };
};
export const updateDeletedMenuLevels = (menuLevels: level[]) => {
  return {
    type: actionTypes.UPDATE_DELETED_MENU_LEVELS,
    menuLevels,
  };
};
export const updateLevelsArtilesDragged = (
  levelArticlesDragged: Partial<LevelArticle[]>,
  level?: Level,
) => {
  return {
    type: actionTypes.UPDATE_LEVELS_ARTICLES_DRAGGED,
    levelArticlesDragged,
    level,
  };
};
/*
 * +--------------------------+
 * | TABLE SUB MENU section   |
 * +--------------------------+
 */

export const dragSubMenu = (subMenu: Menu) => {
  return {
    type: actionTypes.DRAG_NEW_SUB_MENU,
    subMenu,
  };
};
export const selectSubMenus = (subMenu: Menu[]) => {
  return {
    type: actionTypes.SELECT_SUB_MENUS,
    subMenu,
  };
};
export const selectMenuLevels = (menuLevels: level[]) => {
  return {
    type: actionTypes.SELECT_MENUS_LEVELS,
    menuLevels,
  };
};
export const unDragSubMenu = (subMenu: Menu) => {
  return {
    type: actionTypes.unDRAG_NEW_SUB_MENU,
    subMenu,
  };
};

export const updateSubMenus = (subMenus: Menu[]) => {
  return {
    type: actionTypes.UPDATE_SUB_MENU,
    subMenus,
  };
};

export const updateDeletedSubMenus = (subMenus: Menu[]) => {
  return {
    type: actionTypes.UPDATE_DELETED_SUB_MENU,
    subMenus,
  };
};

export const updateSubMenuArticlesDragged = (
  subMenuArticlesDragged: Partial<LevelArticle[]>,
  subMenu?: level,
) => {
  return {
    type: actionTypes.UPDATE_ARTICLES_DRAGGED_SUB_MENU,
    subMenuArticlesDragged,
    subMenu,
  };
};

/*

 * +--------------------------+
 * | ALLERGEN CONFIG section  |
 * +--------------------------+
 */
export const allergenDragged = (allergene: ArticleAllergen) => {
  return {
    type: actionTypes.ALLERGEN_DRAGGED,
    allergene,
  };
};
export const allergenUndragged = (allergene: ArticleAllergen) => {
  return {
    type: actionTypes.ALLERGEN_UNDRAGGED,
    allergene,
  };
};
export const updateAllergens = (allergenes: ArticleAllergen[]) => {
  return {
    type: actionTypes.UPDATE_ALLERGENS,
    allergenes,
  };
};
export const editSuccess = () => {
  return {
    type: actionTypes.EDIT_SUCCESS,
  };
};
export const updateDeletedIngredients = (compositions: Composition[]) => {
  return {
    type: actionTypes.UPDATE_DELETED_INGREDIENTS,
    compositions,
  };
};
export const selectComposition = (
  compositions: Partial<Composition>[] | LevelArticle[],
) => {
  return {
    type: actionTypes.SELECT_COMPOSITION,
    compositions,
  };
};
export const undragAllCompositions = () => {
  return {
    type: actionTypes.UNDRAG_ALL_COMPOSITION,
  };
};
export const updateDeletedAlgs = (allergenes: ArticleAllergen[]) => {
  return {
    type: actionTypes.UPDATE_DELETED_ALGS,
    allergenes,
  };
};
export const getFolder = (_: (_?: UploadFolder[]) => void) => {
  return {
    type: actionTypes.START_GET_FOLDER,
    _,
  };
};

export const getFolderSuccess = (folders: UploadFolder[]) => {
  return {
    type: actionTypes.SUCCESS_GET_FOLDER,
    folders,
  };
};

export const getFolderFail = (error: AxiosError | unknown) => {
  return {
    type: actionTypes.FAIL_GET_FOLDER,
    error,
  };
};

export const postFolder = (folder: UploadFolder, _?: () => void) => {
  return {
    type: actionTypes.POST_FOLDER,
    folder,
    _,
  };
};

export const deleteFolder = (id: number, isSub: boolean, _?: () => void) => {
  return {
    type: actionTypes.DELETE_FOLDER,
    id,
    isSub,
    _,
  };
};

export const postFolderSuccess = () => {
  return {
    type: actionTypes.POST_FOLDER_SUCCESS,
  };
};

export const postFolderFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_FOLDER_FAIL,
    error,
  };
};
/*
 * +--------------------------+
 * | SUBFOLDER section        |
 * +--------------------------+
 */

export const getSubfolder = (id: number, _?: (_?: UploadFolder[]) => void) => {
  return {
    type: actionTypes.START_GET_SUBFOLDER,
    id,
    _,
  };
};
export const getSubfolderSuccess = (subfolders: UploadFolder[]) => {
  return {
    type: actionTypes.SUCCESS_GET_SUBFOLDER,
    subfolders,
  };
};

export const getSubfolderFail = (error: AxiosError | unknown) => {
  return {
    type: actionTypes.FAIL_GET_SUBFOLDER,
    error,
  };
};

export const postSubfolder = (subfolder: UploadFolder, _?: () => void) => {
  return {
    type: actionTypes.POST_SUBFOLDER,
    subfolder,
    _,
  };
};

export const deleteSubfolder = (
  id: number,
  typeOfDelete: string,
  _?: () => void,
) => {
  return {
    type: actionTypes.DELETE_SUBFOLDER,
    id,
    typeOfDelete,
    _,
  };
};

export const postSubfolderSuccess = () => {
  return {
    type: actionTypes.POST_SUBFOLDER_SUCCESS,
  };
};

export const postSubfolderFail = (error: AxiosError) => {
  return {
    type: actionTypes.POST_SUBFOLDER_FAIL,
    error,
  };
};
//IMAGE
export const uploadImage = (
  id: number,
  path: string,
  img: any,
  _: () => void,
) => {
  return {
    type: actionTypes.UPLOAD_IMAGE,
    id,
    path,
    img,
    _,
  };
};
export const getUploadImage = (path: string, _?: (_?: Image[]) => void) => {
  return {
    type: actionTypes.GET_IMAGE,
    path,
    _,
  };
};
export const uploadImageSuccess = (imgs: Image[]) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_SUCCESS,
    imgs,
  };
};
export const deleteUploadImg = (id: number, _?: (_?: Image[]) => void) => {
  return {
    type: actionTypes.DELETE_IMAGE,
    id,
    _,
  };
};
//PURSHASE
export const getPurshaseMethodList = (_?: (_?: purshaseMethod[]) => void) => {
  return {
    type: actionTypes.START_GET_PURSAHSE_METHOD,
    _,
  };
};
//SCREEN
export const getScreens = (
  _?: (_?: screen[]) => void,
  exptInvisible?: boolean,
) => {
  return {
    type: actionTypes.START_GET_SCREEN,
    _,
    exptInvisible,
  };
};
export const getScreenById = (id: number, _?: (_?: screen) => void) => {
  return {
    type: actionTypes.START_GET_SCREEN_BY_ID,
    id,
    _,
  };
};
export const postScreen = (
  screen: screen,
  _?: () => void,
  deletedDivisions?: division[],
) => {
  return {
    type: actionTypes.START_POST_SCREEN,
    screen,
    _,
    deletedDivisions,
  };
};
export const postScreenFail = () => {
  return {
    type: actionTypes.START_POST_SCREEN_FAIL,
  };
};
export const deleteScreen = (id: string, _?: () => void) => {
  return {
    type: actionTypes.START_DELETE_SCREEN,
    id,
    _,
  };
};
//levels
export const getAlllevels = (_?: (levels?: []) => void) => {
  return {
    type: actionTypes.START_GET_LEVELS,
    _,
  };
};
export const getLevelById = (id: string, _?: (_?: Level) => void) => {
  return {
    type: actionTypes.START_GET_LEVEL_BY_ID,
    id,
    _,
  };
};
export const postlevel = (levelPayload: levelPayload, _?: () => void) => {
  return {
    type: actionTypes.START_POST_LEVELS,
    levelPayload,
    _,
  };
};
export const deletelevel = (_?: () => void, id?: string) => {
  return {
    type: actionTypes.START_DELETE_LEVELS,
    _,
    id,
  };
};

//MENU
export const startGetMenu = (_?: (_?: Menu[]) => void) => {
  return {
    type: actionTypes.START_GET_MENUS,
    _,
  };
};
export const startGetMenuEtatDeStock = (
  _?: (_?: Menu[]) => void,
  EtatStock?: number,
  IdEstablishment?: number,
  nom?: string,
) => {
  return {
    type: actionTypes.START_GET_MENUS_STOCK,
    EtatStock,
    IdEstablishment,
    nom,
    _,
  };
};
export const SUCCESSGetMenuEtatDeStock = (data: any) => {
  console.log("data",data);
  return {
    type: actionTypes.SUCCESS_GET_MENUS_STOCK,
    payload: data,
  };
};
export const FAILDGetMenuEtatDeStock = (error: any) => {
  return {
    type: actionTypes.FAIL_GET_MENUS_STOCK,
    payload: error,
  };
};
export const startGetMenuByID = (
  id: string,
  _?: (_?: MenuObjectPayload) => void,
) => {
  return {
    type: actionTypes.START_GET_MENU_BY_ID,
    id,
    _,
  };
};
export const startPostMenu = (menu: Partial<MenuPayload>, _?: () => void) => {
  return {
    type: actionTypes.START_POST_MENUS,
    menu,
    _,
  };
};
export const startDeleteMenu = (id: string, _?: () => void) => {
  return {
    type: actionTypes.DELETE_MENU,
    id,
    _,
  };
};
// CATEGORIES
export const startGetCategories = (
  _?: (_?: CategorySingle[]) => void,
  noEmpty?: boolean,
) => {
  return {
    type: actionTypes.START_GET_CATEGORIES,
    _,
    noEmpty,
  };
};
export const startPostCategory = (
  category: CategoryPayLoad,
  _?: () => void,
) => {
  return {
    type: actionTypes.START_POST_CATEGORY,
    category,
    _,
  };
};
export const startGetCategoryByid = (
  id: string,
  _?: (_?: Category) => void,
) => {
  return {
    type: actionTypes.START_GET_CATEGORY_BY_ID,
    id,
    _,
  };
};
export const startDeleteCategoryByid = (id: string, _?: () => void) => {
  return {
    type: actionTypes.START_DELETE_CATEGORY,
    id,
    _,
  };
};
// CATEGORY_CART
/*
 * +--------------------------+
 * | TABLE SUB MENU section   |
 * +--------------------------+
 */

// Action creator to initiate dragging a new category cart
export const dragCategoryCart = (category: CategorySingle) => {
  return {
    type: actionTypes.DRAG_NEW_CATEGORY_CART,
    category,
  };
};

// Action creator to stop dragging a category cart
export const unDragCategoryCart = (category: CategorySingle) => {
  return {
    type: actionTypes.unDRAG_NEW_CATEGORY_CART,
    category,
  };
};

// Action creator to update the list of category carts
export const updateCategoryCarts = (categoriesCart: CategorySingle[]) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_CART,
    categoriesCart,
  };
};
export const selectCategoryCarts = (categoriesCart: CategorySingle[]) => {
  return {
    type: actionTypes.SELECT_CATEGORY_CARTS,
    categoriesCart,
  };
};
// Action creator to update the list of deleted category carts
export const updateDeletedCategoryCarts = (
  categoriesCart: CategorySingle[],
) => {
  return {
    type: actionTypes.UPDATE_DELETED_CATEGORY_CART,
    categoriesCart,
  };
};

/*
export const updateCategoryCartArticlesDragged = (
  categoryCartArticlesDragged: Partial<LevelArticle[]>,
  categoryCart?: level,
) => {
  return {
    type: actionTypes.UPDATE_ARTICLES_DRAGGED_CATEGORY_CART,
    categoryCartArticlesDragged,
    categoryCart,
  };
};/  */
export const postCart = (
  payload: carteConfig,
  _: (_: CardPostSuccess) => void,
) => {
  return {
    type: actionTypes.POST_CART,
    payload,
    _,
  };
};
export const getFiltredPvList = (
  filter: filtredPvConfig,
  _?: (_: Channel[]) => void,
) => {
  return {
    type: actionTypes.GET_LIST_PV_FILTRED,
    filter,
    _,
  };
};
export const postAssignPv = (
  payloadAssign: {
    actionTarget: string;
    actionTargetId: string;
    cardId?: string;
    pointOfSaleMachines?:
      | {
          id: number;
          pointOfSaleId?: number;
          typeMachineId?: number;
          cardId: string;
          posId?: number;
          typeMachinesId?: number;
        }[]
      | [];
    pointOfSaleMachine_Cards?: {
      id?: number;
      pointOfSaleId?: number;
      typeMachineId?: number;
      cardId?: string;
    }[];
  },
  _?: () => void,
) => {
  return {
    type: actionTypes.POST_ASSIGN_PV,
    payloadAssign,
    _,
  };
};
export const updateCategoriesCard = (categoriesCart: CategorySingle[]) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_CARD,
    categoriesCart,
  };
};
export const dragUnDragCartCat = (
  payloadAssign: modalData,
  _?: (_: modalData) => void,
) => {
  return {
    type: actionTypes.DRAG_UNDRAG_PV,
    payloadAssign,
    _,
  };
};
export const getCardById = (id: string, _?: (_: CardByID) => void) => {
  return {
    type: actionTypes.GET_CARD_BY_ID,
    id,
    _,
  };
};
export const clearItemStore = (item: string) => {
  return {
    type: actionTypes.CLEAR_STORE_ITEM,
    item,
  };
};
