import * as actionTypes from '../actions/actionTypes';
interface UpdateLoyaltyRuleState {
  loading: boolean;
  error: string | null;
}

const initialState: UpdateLoyaltyRuleState = {
  loading: false,
  error: null,
};

const updateLoyaltyRuleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOYALTY_RULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_LOYALTY_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_LOYALTY_RULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default updateLoyaltyRuleReducer;
