//! AUTHENTIFICATION MIDDELWARE
import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  failure,
  addEtablissementSuccess,
  addEtablissementFailure,
  setAllEtablissement,
  setEtablissementsTotalPagesNumber,
  setEtablissementByIdInfos,
  setEtablissementByIdSheet,
  setEtablissementByIdFinancialInfos,
  editEtablissementSuccess,
  editEtablissementFailure,
  resetLoggedIn,
  resetPasswordSuccesAction,
  getListStatutsEstablishementsFailure,
  getListStatutsEstablishementsSuccess,
  updateAbonnementStatusSuccess,
  updateAbonnementStatusFailure,
  activateDeactivateEstablishementFailure,
  activateDeactivateEstablishementSuccess,
  createAccountSuccess,
  createAccountFailure,
  updateAccountFailure,
  updateAccountSuccess,
} from '../actions/actions';
import { serviceEtablissementModels } from '../../models/services/module.etablissement';
import etablissementApi from '../../services/apiHandlers/etablissementApi';
import { unauthorizedError } from '../../utils/unauthorizedError';

export function* addEtablissementSaga(
  action: serviceEtablissementModels.addEtablissementAction,
) {
  try {
    const response: AxiosResponse = yield etablissementApi.addEtablissement(
      action.payload,
    );
    if (response.data?.result?.establishement_id) {
      yield put(
        addEtablissementSuccess(response.data.result.establishement_id),
      );
    } else {
      yield put(addEtablissementFailure());
    }
  } catch (error: any) {
    // if (unauthorizedError(error)) {
    //   yield put(resetLoggedIn());
    //   yield put(resetPasswordSuccesAction(false));
    // }
    yield put(addEtablissementFailure());
  }
}
export function* getAllEtablissementsSaga(
  action: serviceEtablissementModels.getAllEtablissementAction,
) {
  try {
    const response: AxiosResponse = yield etablissementApi.getAllEtablissements(
      action.payload,
    );

    if (response.data) {
      yield put(setAllEtablissement(response.data.result));
      yield put(
        setEtablissementsTotalPagesNumber(response.data.pagination.nbPage),
      );
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}

export function* getEtablissementByIdInfosSaga(
  action: serviceEtablissementModels.getEtablissementAction,
) {
  try {
    const response: AxiosResponse =
      yield etablissementApi.getEtablissementByIdInfos(action.payload.id);

    if (response.data) {
      yield put(setEtablissementByIdInfos(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getEtablissementByIdSheetSaga(
  action: serviceEtablissementModels.getEtablissementAction,
) {
  try {
    const response: AxiosResponse =
      yield etablissementApi.getEtablissementByIdSheet(action.payload.id);

    if (response.data) {
      yield put(setEtablissementByIdSheet(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getEtablissementByIdFinancialInfosSaga(
  action: serviceEtablissementModels.getEtablissementAction,
) {
  try {
    const response: AxiosResponse =
      yield etablissementApi.getEtablissementFinancialInfos(action.payload.id);

    if (response.data) {
      yield put(setEtablissementByIdFinancialInfos(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* editEtablissementFinancialInfoSaga(
  action: serviceEtablissementModels.formDataFinancialInfosTypeAction,
) {
  try {
    const response: AxiosResponse =
      yield etablissementApi.editEtablissementFinancialInfos(action.payload);

    if (response.data) {
      yield put(setEtablissementByIdFinancialInfos(response.data.result));
      yield put(editEtablissementSuccess());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
    yield put(editEtablissementFailure());
  }
}
export function* editEtablissementGeneralInfoSaga(
  action: serviceEtablissementModels.formDataGeneralInfosTypeAction,
) {
  try {
    const response: AxiosResponse =
      yield etablissementApi.editEtablissementGenaralInfos(action.payload);

    if (response.data) {
      yield put(setEtablissementByIdFinancialInfos(response.data.result));
      yield put(editEtablissementSuccess());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
    yield put(editEtablissementFailure());
  }
}
export function* getListStatutsEstablishementsSaga() {
  try {
    const response: AxiosResponse =
      yield etablissementApi.getListStatutsEstablishements();

    if (response.data) {
      yield put(getListStatutsEstablishementsSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(getListStatutsEstablishementsFailure(error.message));
  }
}

export function* updateStatusAbonnmentSaga(action: {
  type: string;
  payload: { idAbonnement: number; data: boolean; _?: () => void };
}) {
  const { idAbonnement, data, _ } = action.payload;
  try {
    const response: AxiosResponse =
      yield etablissementApi.updateStatusAbonnment(idAbonnement, data);
    if (response.data) {
      yield put(updateAbonnementStatusSuccess(response.data.result));
      if (_) {
        _();
      }
    }
  } catch (error: string | any) {
    yield put(updateAbonnementStatusFailure(error.message as string));
  }
}

export function* activateDeactivateEstablishementSaga(action: {
  type: string;
  payload: {
    idEstablishement: number;
    data: boolean;
    _?: () => void;
  };
}) {
  const { idEstablishement, data, _ } = action.payload;
  try {
    const response: AxiosResponse =
      yield etablissementApi.activateDeactivateEstablishement(
        idEstablishement,
        data,
      );
    if (response.data) {
      yield put(activateDeactivateEstablishementSuccess(response.data.result));
      _ && _();
    }
  } catch (error: string | any) {
    yield put(activateDeactivateEstablishementFailure(error.message as string));
  }
}
export function* CreateAccountSaga(action: { type: string; payload: any }) {
  try {
    const response: AxiosResponse = yield etablissementApi.CreateAccount(
      action.payload,
    );
    if (response.data) {
      yield put(createAccountSuccess(action.payload, response?.data?.result));
      window.open(response?.data?.result?.accountUrl, '_blank');
    }
  } catch (error: string | any) {
    yield put(createAccountFailure(action.payload, error));
  }
}

export function* UpdateAccountSaga(action: { type: string; payload: any }) {
  try {
    const response: AxiosResponse = yield etablissementApi.UpdateAccount(
      action.payload,
    );
    if (response.data) {
      yield put(updateAccountSuccess(action.payload, response));
      window.open(response?.data?.result?.accountUrl, '_blank');
    }
  } catch (error: string | any) {
    yield put(updateAccountFailure(action.payload, error.message));
  }
}
