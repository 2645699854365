import React, { ChangeEvent, useState } from 'react';
import SwitcherCheckbox from '../../../../components/Catalogue/SwitcherCheckbox';
import InputField from '../../../../components/Input/Input';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';
/**
 * ModeDePaiement component description.
 *
 * @ModeDePaiement
 *
 */

interface ModeDePaiementProps {
  title?: string;
  state: any;
  handleInputChange: any;
  handleSwitcherChange: any;
}

const ModeDePaiement: React.FC<ModeDePaiementProps> = ({
  state,
  handleInputChange,
  handleSwitcherChange,
}) => {
  return (
    <div className="ModeDePaiement tab-content-section">
      <div className="block-wrapper fl_col gp20">
        <div className="fl_row gp20">
          <SwitcherCheckbox
            onChange={() => handleSwitcherChange('supportOnSpot')}
            ischecked={state?.supportOnSpot}
            label="Paiement sur place"
            id="PaiementSurPlace"
          />
          <SwitcherCheckbox
            onChange={() => handleSwitcherChange('isStripeActive')}
            ischecked={state?.isStripeActive || false}
            label="Paiement en ligne"
            id="PaiementEnLigne"
          />
        </div>
        {state?.isStripeActive && state?.isStripeActive !== null && (
          <>
            <SwitcherCheckbox
              onChange={() => handleSwitcherChange('useStripePlatformAccount')}
              ischecked={state?.useStripePlatformAccount}
              label="Compte Plateforme"
              id="ComptePlateforme"
            />
            {!state?.useStripePlatformAccount && (
              <>
                <InputField
                  isRequired={true}
                  type="text"
                  name="secret_key"
                  placeholder="XXXXXXXXXXXXXXX"
                  label="Clé privé du service (secret key)"
                  style="form"
                  value={state?.stripePrivateApiKey}
                  onChange={(e) =>
                    handleInputChange('stripePrivateApiKey', e.target.value)
                  }
                />
                <InputField
                  isRequired={true}
                  type="text"
                  name="public_key"
                  placeholder="XXXXXXXXXXXXXXX"
                  label="Clé publique du service"
                  style="form"
                  value={state?.stripePublicApiKey}
                  onChange={(e) =>
                    handleInputChange('stripePublicApiKey', e.target.value)
                  }
                />
              </>
            )}

            {state?.useStripePlatformAccount &&
              state?.useStripePlatformAccount !== null && (
                <>
                  <InputField
                    isRequired={true}
                    type="text"
                    name="stripe_fees"
                    placeholder="XXXXXXXXXXXXXXX"
                    label="Frais de paiement Stripe"
                    style="form"
                    value={state?.stripepaymentfee}
                    onChange={(e) =>
                      handleInputChange(
                        'stripepaymentfee',
                        Number(e.target.value),
                      )
                    }
                  />
                </>
              )}
          </>
        )}
        {state?.supportOnSpot && state?.supportOnSpot !== null && <></>}
      </div>
      {/* <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div> */}
    </div>
  );
};

export default ModeDePaiement;
