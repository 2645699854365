import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  result: null,
  error: null,
};

const loyaltyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_LOYALTY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_LOYALTY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
      };
    case actionTypes.FETCH_LOYALTY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default loyaltyReducer;
