import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loadingUpdateItems: {},
  account: null,
  error: null,
};

const UpdateaccountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingUpdateItems: {
          ...state.loadingUpdateItems,
          [action.payload]: true, 
        },
        error: null,
      };
    case actionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingUpdateItems: {
          ...state.loadingUpdateItems,
          [action.payload.establishementId]: false, 
        },
        account: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        loadingUpdateItems: {
          ...state.loadingUpdateItems,
          [action.payload.establishementId]: false,
        },
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default UpdateaccountReducer;