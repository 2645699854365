import React, { useState } from 'react';
//import SelectInput from '../../components/Input/SelectInput';
import SelectInputMarketing from '../../components/Input/SelectInputMarketing';
import ExistCompanyIcon from '../../assets/icons/ExistCompanyIcon.svg';
import ButtonElement from '../../components/button/button';
import { languageData } from '../../i18n';
import { Company } from './SelectOptionMarketing';
import { Option } from '../../utils/catalogueHelper/FamilyHelper';
import IconErrorRed from '../../assets/icons/IconErrorRed.svg';

/**
 * ModalSelectCompany component description.
 *
 * @ModalSelectCompany
 *
 */

interface ModalSelectCompanyProps {
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
  companies?: Company[];
  inputValue?: any;
  setInputValue: React.Dispatch<React.SetStateAction<string | number>>;
  filteredItems: any;
  setFilteredItems: any;
  showDropdown: any;
  setShowDropdown: any;
  filterItems: any;
  handleChange: any;
  isButtonDisabled?: boolean;
  onClickAccede?: boolean;
}

const ModalSelectCompany: React.FC<ModalSelectCompanyProps> = ({
  onClickConfirm,
  onClickCancel,
  companies,
  inputValue,
  setInputValue,
  filteredItems,
  showDropdown,
  setShowDropdown,
  filterItems,
  handleChange,
  setFilteredItems,
  isButtonDisabled,
  onClickAccede,
}) => {
  return (
    <div className="ModalSelectCompany">
      <div className="modal-content-wrapper fl_col gp40 h-full">
        <div className="block-content fl-1 rd16 fl_col gp5 relative">
          <div className="side-wrapper fl_col gp40">
            <div className="text-block fl_col ai_c gp10 txt_center">
              <img width={'80px'} src={ExistCompanyIcon} alt="" />
              <p className="p25 w-700">
                {languageData.fr.Marketing.PopUpMarketing.Choix_Company}
              </p>
            </div>
            <div className="fl_col gp16">
              <SelectInputMarketing
                setInputValue={setInputValue}
                filterItems={filterItems}
                inputValue={inputValue}
                setFilteredItems={setFilteredItems}
                filteredItems={filteredItems}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                onChange={handleChange}
                options={companies as Company[]}
                placeholder={'Sélectionner une entreprise'}
                isRequired={false}
                label={languageData.fr.Marketing.PopUpMarketing.lableCompany}
                name="entreprise"
                target="reference"
              />
            </div>
          </div>
          {onClickAccede ? (
            <div className="fl_row ai_c gp4 pd4-r-l error-txt">
              <img src={IconErrorRed} alt="" />
              <p className="p14 Independence-txt w-300">
                Veuillez choisir une entreprise.
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="footer-modal fl_row gp20">
          <div>
            <ButtonElement
              className="medium"
              type="TextButton"
              textSize="p18"
              text={'Annuler'}
              textColor="white-txt"
              buttonSize="full"
              radius="rd12"
              onClick={onClickCancel}
              buttonStyle="btn-grey-light"
            />
          </div>

          <div className="fl-1">
            <ButtonElement
              className="medium"
              type="TextButton"
              textSize="p18"
              text={'Accéder'}
              textColor="white-txt"
              buttonSize="full"
              radius="rd12"
              isDisabled={false}
              onClick={onClickConfirm}
              buttonStyle="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSelectCompany;
