import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import MarketsIconCircle from '../../../assets/icons/MarketsIconCircle.svg';
import EditGreyIcon from '../../../assets/icons/EditGreyIcon.svg';
import DeleteGreyIcon from '../../../assets/icons/DeleteGreyIcon.svg';
import CardContainer from '../../CardContainer';
import TableHeaderCustom from '../../../components/Table/TableHeaderCustom';
import Modal from '../../../components/Modal/Modal';
import { languageData } from '../../../i18n';
import EditUserProfilePassword from '../../Users/EditUserProfilePassword';
import ModalPointsDeVentes from './ModalPointsDeVentes';
import { useEffect, useState } from 'react';
import ModalEditPointFidelite from './ModalEditPointFidelite';
import ModalAddPointFidelite from './ModalAddPointFidelite';
import ModalDeletePointFidelite from './ModalDeletePointFidelite';
import ConfirmModalPointFidelite from './ConfirmModalPointFidelite';
import { cloneDeep } from 'lodash';
import {
  affectLoyaltyRuleToEstablishments,
  createLoyaltyRuleRequest,
  deleteLoyaltyRuleRequest,
  fetchEstablishmentsRequest,
  getListLoyaltyRulesRequest,
  setClearTheError,
  updateLoyaltyRuleRequest,
} from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { serviceMarketingModels } from '../../../models/services/module.serviceMarketingModels';
import { useSelector } from 'react-redux';
import { fields, fieldsUpdates } from './ConstantElement';

interface Field {
  name: string;
  label: string;
  required?: boolean;
  pattern?: string;
  errorMessage?: string;
  type?: string;
}
const initialFormData = { isActive: false };
export default function FideliteTable({ setShowFideliteTable }: any) {
  const [showModalPointsDeVentes, setShowModalPointsDeVentes] = useState(false);
  const [showModalEditPointFidelite, setShowModalEditPointFidelite] =
    useState(false);
  const [showConfirmModalPointFidelite, setShowConfirmModalPointFidelite] =
    useState(false);
  const [showModalAddPointFidelite, setModalAddPointFidelite] = useState(false);
  const [showModalDeletePointFidelite, setModalDeletePointFidelite] =
    useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [regleId, setRegleId] = useState<number | undefined>();
  const [localBooks, setLocalBooks] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState(new Set());

  const [singleRegle, setSingleRegle] =
    useState<serviceMarketingModels.Regle>();
  const [errors, setErrors] = useState({});
  const { inputtheValue } = useSelector(
    (state: any) => state.MarketingListCompanyReducer,
  );
  const { data } = useSelector((state: any) => state.loyaltyRuleReducer);
  const { establishments, loading } = useSelector(
    (state: any) => state.pointdeVenteReducer,
  );
  const { error } = useSelector((state: any) => state.establishmentEffected);
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  const handleChangee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setSingleRegle((prevState) => ({
      ...prevState!,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validate = (fields: Field[], formData: any): boolean => {
    const newErrors: Record<string, string> = {};

    fields.forEach((field) => {
      const value = formData[field.name];

      // Check for required fields
      if (field.required && !value) {
        newErrors[field.name] = `${field.label} obligatoire`;
      }

      // Check for pattern validation
      else if (
        field.pattern &&
        value &&
        !new RegExp(field.pattern).test(value)
      ) {
        newErrors[field.name] = field.errorMessage || `Invalid ${field.label}`;
      }

      //check if the field Désignation is more than 3 in length if not show error message
      else if (field.name === 'designation') {
        if (value && value.length < 3) {
          newErrors[field.name] =
            `${field.label} doit avoir au moins 3 caractères`;
        }
      }
      // Check for zero or negative values (if the field is numeric)
      else if (field.type === 'number') {
        if (value === 0) {
          newErrors[field.name] = `${field.label} ne peut pas être zéro`;
        } else if (value <= 0) {
          newErrors[field.name] = `${field.label} doit être supérieur à 0`;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // Create a deep copy of establishments
    const deepCopyEstablishments = cloneDeep(establishments);

    // Use the deep copy for local state
    setLocalBooks(deepCopyEstablishments);

    // Initialize selectedBooks based on the completed status from the API
    const initiallySelected = deepCopyEstablishments
      .filter((establishment: any) => establishment.isChecked) // Select establishments where completed is true
      .map((establishment: any) => establishment.id); // Get their IDs
    setSelectedBooks(new Set(initiallySelected));
  }, [establishments]);

  const handleSubmit = () => {
    if (validate(fields, formData)) {
      dispatch(
        createLoyaltyRuleRequest(
          formData as serviceMarketingModels.LoyaltyRulePayload,
          inputtheValue,
          () => {
            dispatch(getListLoyaltyRulesRequest(inputtheValue));
            setFormData({ isActive: false });
            setModalAddPointFidelite(false);
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (inputtheValue) {
      dispatch(getListLoyaltyRulesRequest(inputtheValue));
    }
  }, [inputtheValue]);

  const handleDeleteRegle = (regleId: number) => {
    if (regleId) {
      dispatch(
        deleteLoyaltyRuleRequest(regleId, () => {
          dispatch(getListLoyaltyRulesRequest(inputtheValue));
        }),
      );
      setModalDeletePointFidelite(false);
    }
  };
  const handleSubmitUpdate = () => {
    if (validate(fieldsUpdates, singleRegle)) {
      dispatch(
        updateLoyaltyRuleRequest(
          singleRegle?.id as number,
          singleRegle as any,
          () => {
            dispatch(getListLoyaltyRulesRequest(inputtheValue));
          },
        ),
      );
      setShowModalEditPointFidelite(false);
    }
  };

  const fetchPointDeVente = (singleRegle: any) => {
    if (!singleRegle?.id) {
      return;
    }
    dispatch(
      fetchEstablishmentsRequest(inputtheValue, singleRegle?.id as number),
    );
  };

  const handleSelectAll = () => {
    if (selectedBooks.size === localBooks.length) {
      // If all books are already selected, clear the selection and set completed to false
      setSelectedBooks(new Set());
      setLocalBooks((prevLocalBooks: any) =>
        prevLocalBooks.map((establishment: any) => ({
          ...establishment,
          isChecked: false,
        })),
      );
    } else {
      // Otherwise, select all books and set completed to true
      setSelectedBooks(new Set(localBooks.map((book: any) => book.id)));
      setLocalBooks((prevLocalBooks: any) =>
        prevLocalBooks.map((establishment: any) => ({
          ...establishment,
          isChecked: true,
        })),
      );
    }
  };

  const handleCheckboxChange = (id: any) => {
    // Toggle the completed status in the local copy
    setLocalBooks((prevLocalBooks: any) =>
      prevLocalBooks.map((establishment: any) =>
        establishment.id === id
          ? { ...establishment, isChecked: !establishment.isChecked }
          : establishment,
      ),
    );

    // Update the selection
    const updatedBooks = new Set(selectedBooks);
    if (updatedBooks.has(id)) {
      updatedBooks.delete(id);
    } else {
      updatedBooks.add(id);
    }
    setSelectedBooks(updatedBooks);
  };

  // useEffect to watch for error changes
  useEffect(() => {
    if (
      error?.codeFI?.code === 945 &&
      error?.codeFI?.message ===
        'you_have_establishements_affected_in_another_rule_that_you_can_crush'
    ) {
      // Show the confirmation modal when the error condition is met
      setShowConfirmModalPointFidelite(true);
    }
  }, [error]); // Run this effect whenever the error state changes

  const confirmeffectedIstablishment = () => {
    // If no error, directly dispatch the action with forcedToCrush = false
    if (
      !error ||
      error?.codeFI?.code !== 945 ||
      error?.codeFI?.message !==
        'you_have_establishements_affected_in_another_rule_that_you_can_crush'
    ) {
      confirmeffectedIstablishmentfalse();
    }
  };

  const confirmeffectedIstablishmenttrue = () => {
    // Dispatch the action with forcedToCrush = true
    dispatch(
      affectLoyaltyRuleToEstablishments(
        singleRegle?.id as number,
        Array.from(selectedBooks) as any,
        true,
        () => {
          // Close the confirmation modal
          setShowConfirmModalPointFidelite(false);
          setShowModalEditPointFidelite(false);
          setShowModalPointsDeVentes(false);
        },
      ),
    );
  };

  const confirmeffectedIstablishmentfalse = () => {
    // Dispatch the action with forcedToCrush = false
    dispatch(
      affectLoyaltyRuleToEstablishments(
        singleRegle?.id as number,
        Array.from(selectedBooks) as any,
        false,
        () => {
          setShowModalPointsDeVentes(false);
        },
      ),
    );
  };

  return (
    <div className="FideliteTable fl_col gp10 h-full w-full">
      <TableHeaderCustom
        type={'add'}
        title="Points de fidélité sur le montant du panier"
        // description="Gérer vos points de fidélités"
        largeTitle
        onClickAdd={() => setModalAddPointFidelite(true)}
        textAdd="Ajouter"
        onClickBack={() => setShowFideliteTable(false)}
      />
      <CardContainer type="standard" fullHeight>
        <DesktopTableContainer>
          <Table className="Table TableEtablissement rd12">
            <Thead>
              <Tr>
                <Td type="text" value={'Désignation'} />
                <Td
                  type="text"
                  value={'Montant de conversion  (€)'}
                  width={15}
                />
                <Td
                  type="text"
                  value={'Nombre de points à gagner'}
                  width={10}
                />
                <Td type="text" value={'Statut'} width={30} />
                <Td type="text" value={'Points de vente'} />
                <Td type="emptyRow" width={3} />
                <Td type="emptyRow" width={3} />
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((regle: serviceMarketingModels.Regle) => {
                return (
                  <Tr>
                    <Td
                      type="text"
                      value={
                        regle?.designation?.length > 20
                          ? regle?.designation.substring(0, 20) + '...'
                          : regle?.designation
                      }
                    />
                    <Td
                      type="text"
                      value={regle?.conversionAmount}
                      width={15}
                    />
                    <Td
                      type="text"
                      value={
                        regle?.loyaltyPointsNumber ||
                        regle?.loyaltyPointsAssociated
                      }
                      width={10}
                    />
                    <Td
                      type="switcher"
                      statusToggle={'active'}
                      ischecked={regle?.isActive}
                      // onChange={() => alert('')}
                      width={30}
                    />
                    <Td
                      type="icon"
                      iconSrc={MarketsIconCircle}
                      onClick={() => {
                        setShowModalPointsDeVentes(true);
                        fetchPointDeVente(regle);
                        setSingleRegle(regle);
                      }}
                    />
                    <Td
                      type="icon"
                      iconSrc={EditGreyIcon}
                      onClick={() => {
                        setShowModalEditPointFidelite(true);
                        setSingleRegle(regle);
                      }}
                      width={3}
                    />
                    <Td
                      type="icon"
                      iconSrc={DeleteGreyIcon}
                      onClick={() => {
                        setModalDeletePointFidelite(true);
                        setRegleId(regle?.id as number);
                      }}
                      width={3}
                    />
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </DesktopTableContainer>
      </CardContainer>
      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={'Liste des établissements'}
        visibility={showModalPointsDeVentes}
        close
        maxWidth={640}
        content={
          <ModalPointsDeVentes
            onClickClose={() => {
              setShowModalPointsDeVentes(false);
              dispatch(setClearTheError());
            }}
            onClickValidate={confirmeffectedIstablishment}
            loading={loading}
            establishments={establishments}
            onChange={handleSelectAll}
            checked={
              selectedBooks.size === localBooks.length && localBooks.length > 0
            }
            localBooks={localBooks}
            handleCheckboxChange={handleCheckboxChange}
            confirmeffectedIstablishmentfalse={
              confirmeffectedIstablishmentfalse
            }
            error={
              error?.codeFI?.code === 945 &&
              error?.codeFI?.message ===
                'you_have_establishements_affected_in_another_rule_that_you_can_crush'
            }
          />
        }
        onClickClose={() => {
          setShowModalPointsDeVentes(false);
          dispatch(setClearTheError());
        }}
      />

      <Modal
        className="ModalEditPointFidelite"
        version="custom"
        visibility={showModalEditPointFidelite}
        close
        maxWidth={640}
        content={
          <ModalEditPointFidelite
            singleRegle={singleRegle}
            onClickSave={() => handleSubmitUpdate()}
            onClickClose={() => {
              setShowModalEditPointFidelite(false);
              setErrors({});
            }}
            // isActivated
            onChange={handleChangee}
            errors={errors}
            onKeyDown={(e: any) => {
              if (e.key === '.' || e.key === ',') {
                e.preventDefault(); // Prevent decimal input
              }
            }}
          />
        }
        onClickClose={() => {
          setShowModalEditPointFidelite(false);
          setErrors({});
        }}
      />

      <Modal
        className="ModalAddPointFidelite"
        version="custom"
        visibility={showModalAddPointFidelite}
        close
        maxWidth={640}
        content={
          <ModalAddPointFidelite
            onClickSave={handleSubmit}
            onClickClose={() => {
              setModalAddPointFidelite(false);
              setErrors({});
              setFormData(initialFormData);
            }}
            formData={formData}
            onChange={(e: any) => handleChange(e)}
            errors={errors}
            onKeyDown={(e: any) => {
              if (e.key === '.' || e.key === ',') {
                e.preventDefault(); // Prevent decimal input
              }
            }}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => {
          setModalAddPointFidelite(false);
          setErrors({});
          setFormData(initialFormData);
        }}
      />

      <Modal
        className="ModalDeletePointFidelite"
        version="custom"
        visibility={showModalDeletePointFidelite}
        close
        maxWidth={640}
        content={
          <ModalDeletePointFidelite
            onClickConfirm={() => handleDeleteRegle(regleId as number)}
            onClickClose={() => setModalDeletePointFidelite(false)}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => setModalDeletePointFidelite(false)}
      />

      <Modal
        className="ConfirmModalPointFidelite"
        version="custom"
        visibility={showConfirmModalPointFidelite}
        close
        maxWidth={640}
        content={
          <ConfirmModalPointFidelite
            onClickConfirm={confirmeffectedIstablishmenttrue}
            onClickClose={() => {
              setShowConfirmModalPointFidelite(false);
              dispatch(setClearTheError());
            }}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => {
          setShowConfirmModalPointFidelite(false);
          dispatch(setClearTheError());
        }}
      />
      {/* <h3 className="empty-table">
        Les règles de fidélité créer vont être affichés ici
      </h3> */}
    </div>
  );
}
