import React, { useEffect, useState } from 'react';
import MarketingOptionCard from './MarketingOptionCard';
import FidelityImgCardSelect from '../../assets/icons/FidelityImgCardSelect.svg';
import Modal from '../../components/Modal/Modal';
import ModalSelectCompany from './ModalSelectCompany';
import SelectFidelitePanierOption from './FidelitePanier/SelectFidelitePanierOption';
import CardContainer from '../CardContainer';
import TableHeaderCustom from '../../components/Table/TableHeaderCustom';
import FideliteTable from './FidelitePanier/FideliteTable';
import {
  activateDeactivateLoyaltyRequest,
  ClearsetTheInputValue,
  fetchCompaniesRequest,
  fetchLoyaltyStatusRequest,
  setTheInputValue,
} from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Option } from '../../utils/catalogueHelper/FamilyHelper';

/**
 * SelectOptionMarketing component description.
 *
 * @SelectOptionMarketing
 *
 */

interface SelectOptionMarketingProps {
  title?: string;
}
export interface Company {
  id: number;
  nom: string;
}

const SelectOptionMarketing: React.FC<SelectOptionMarketingProps> = ({
  title,
}) => {
  const [showModalSelectCompany, setShowModalSelectCompany] = useState(false);
  const [showMarketingOptionCard, setShowMarketingOptionCard] = useState(true);
  const [showSelectFidelitePanierOption, setShowSelectFidelitePanierOption] =
    useState(false);
  const [inputValue, setInputValue] = useState<string | number>('');
  const [filteredItems, setFilteredItems] = useState<Option[]>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCentrelaisedCompany, setIsCentrelaisedCompany] = useState(false);
  const [onClickAccede, setOnClickAccede] = useState(false);
  const { companies, singleCompany, inputtheValue } = useSelector(
    (state: any) => state.MarketingListCompanyReducer,
  );
  const { result } = useSelector((state: any) => state.loyaltyReducerReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsCentrelaisedCompany(result);
  }, [result]);

  useEffect(() => {
    if (inputValue) {
      setOnClickAccede(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputtheValue) {
      dispatch(fetchLoyaltyStatusRequest(inputtheValue));
    }
  }, [inputtheValue]);

  useEffect(() => {
    dispatch(fetchCompaniesRequest());
    setFilteredItems(companies);
  }, [dispatch]);

  useEffect(() => {
    if (singleCompany?.hasOneCompany) {
      setShowMarketingOptionCard(false);
      setShowSelectFidelitePanierOption(true);
      dispatch(setTheInputValue(singleCompany?.companyId));
    }
  }, [singleCompany?.hasOneCompany, singleCompany?.companyId]);

  const filterItems = (value: string) => {
    if (value.trim() === '') {
      setFilteredItems(companies);
    } else {
      const filtered = companies.filter((companie: Company) =>
        companie.nom.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredItems(filtered);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInputValue(value);
    filterItems(value);
    setShowDropdown(true);
  };
  const isButtonDisabled = inputValue === '';

  const handleToggle = () => {
    setIsCentrelaisedCompany((prevState) => !prevState);

    dispatch(
      activateDeactivateLoyaltyRequest(
        inputValue as any,
        !isCentrelaisedCompany,
      ),
    );
  };

  return (
    <div className="SelectOptionMarketing h-full">
      {showMarketingOptionCard && (
        <div className="fl_col gp10 h-full w-full">
          <TableHeaderCustom
            className="welcomeTab"
            type={'title'}
            title="Outils de Marketing et Engagement Client"
            largeTitle
          />
          <CardContainer type="standard" fullHeight>
            <div className="select-wrapper select-wrapper-grid">
              <MarketingOptionCard
                style="large"
                title="Fidélité"
                description="Récompensez vos clients avec des offres exclusives, des remises et des promotions personnalisées pour renforcer leur engagement."
                onClick={() => setShowModalSelectCompany(true)}
                icon={FidelityImgCardSelect}
              />
            </div>
          </CardContainer>
        </div>
      )}

      {showSelectFidelitePanierOption && (
        <div className="fl_col gp10 h-full w-full">
          <div className="select-wrapper">
            <SelectFidelitePanierOption
              IsCentrelaisedCompany={isCentrelaisedCompany}
              setShowSelectFidelitePanierOption={
                setShowSelectFidelitePanierOption
              }
              setInputValue={setInputValue}
              setShowMarketingOptionCard={setShowMarketingOptionCard}
              handleToggle={handleToggle}
            />
          </div>
        </div>
      )}

      {/* {showFideliteTable && <FideliteTable />} */}

      <Modal
        className="SelectEntrepriseModal"
        version="custom"
        content={
          <ModalSelectCompany
            companies={companies}
            onClickCancel={() => {
              setShowModalSelectCompany(false);
               dispatch(ClearsetTheInputValue());
               setOnClickAccede(false);
              setInputValue('');
            }}
            onClickConfirm={() => {
              if (inputValue == '') {
                setOnClickAccede(true);
                return;
              }
              setShowModalSelectCompany(false);
              setShowMarketingOptionCard(false);
              setShowSelectFidelitePanierOption(true);
              dispatch(setTheInputValue(inputValue as any));
            }}
            onClickAccede={onClickAccede}
            inputValue={inputValue}
            setInputValue={setInputValue}
            filteredItems={filteredItems}
            setFilteredItems={setFilteredItems}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            filterItems={filterItems}
            handleChange={handleChange}
            isButtonDisabled={isButtonDisabled}
          />
        }
        title={''}
        visibility={showModalSelectCompany}
        maxWidth={600}
        height={435}
      />
    </div>
  );
};

export default SelectOptionMarketing;
