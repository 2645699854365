import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  addArticleActionSaga,
  articleAction,
} from '../../../models/services/module.catalogue';
import {
  getArticleFail,
  getArticleSuccess,
  postArticleSuccess,
} from '../../actions/catalogues/actions';
export function* startGetArticles(action: articleAction) {
  try {
    const response: AxiosResponse = action.params?.etat_de_stock
      ? yield catalogueApi.getArticleListEtatDeStock(action.params)
      : yield catalogueApi.getArticleList(action.params);
    if (response.data) {
      yield put(
        getArticleSuccess(
          action.params?.etat_de_stock
            ? response.data?.data?.result ?? []
            : response.data?.data?.result ?? [],
        ),
      );
      action._ &&
        action._(
          action.params?.etat_de_stock
            ? response.data?.data?.result ?? []
            : response.data?.data?.result ?? [],
        );
    }
  } catch (error: any) {
    yield put(getArticleFail(error));
  }
}
export function* startPostArticles(action: addArticleActionSaga) {
  try {
    const response: AxiosResponse = yield catalogueApi.addArticle(
      action.article,
    );
    if (response.data) {
      yield put(postArticleSuccess());
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteArticle(action: addArticleActionSaga) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteArticleWithId(
      action.id as string,
    );
    if (response.data) {
      yield put(postArticleSuccess());
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startGetArticleWithId(action: addArticleActionSaga) {
  try {
    const response: AxiosResponse = yield catalogueApi.getArticleWithId(
      action.id as string,
    );
    if (response.data) {
      yield put(postArticleSuccess());
      action._ ? action._(response.data?.data?.result) : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
