import React, { ChangeEvent, useEffect, useState } from 'react';

import FilterIconCircleGrey from '../../../../assets/icons/FilterIconCircleGrey.svg';
import EmptyResultImg from '../../../../assets/icons/EmptyResultImg.png';

import InputField from '../../../../components/Input/Input';
import SelectInputCatalogue from '../../../../components/Input/SelectedInputCatalogue';
import CheckBox from '../../../../components/Catalogue/Checkbox';
import ItemStock from '../../../../container/Catalogue/EtatDeStock/ItemStock';
import Modal from '../../../../components/Modal/Modal';
import UserRoleModalContent from '../../../../components/Modal/UserRoleModalContent';
import { languageData } from '../../../../i18n';
import EditStockModalContent from '../../../../container/Catalogue/EtatDeStock/EditStockModalContent';
import EtatDeStockCard from '../../../../container/Catalogue/EtatDeStock/EtatDeStockCard';
import ChooseEtablissement from './ChooseEtablissement';
import HeaderArticle from '../../../../components/Catalogue/Articles/Header';
import { convertToOptions } from '../../../../utils/catalogueHelper/FamilyHelper';
import {
  ArticleSaved,
  Family,
  getArticleParams,
} from '../../../../models/services/module.catalogue';
import { useDispatch } from 'react-redux';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../../../utils/Notificationprovider';
import {
  getArticle,
  getFamily,
  getSubFamily,
} from '../../../../store/actions/catalogues/actions';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';
import StockFilterToggle from './StockFilterToggle';
import HeaderEtatDeStock from './HeaderEtatDeStock';
import { useSelector } from 'react-redux';
export default function ListingEtatDeStockContainer({
  idEstablishment,
}: {
  idEstablishment: string;
}) {
  const [families, setfamilies] = useState<Family[]>();
  const [subFamiles, setsubFamiles] = useState<Family[]>();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [currentActiveToggle, setcurrentActiveToggle] = useState(-1);
  const [searchValue, setsearchValue] = useState<string>();
  const [selectedids, setselectedids] = useState<number[]>([]);
  const [articles, setArticles] = useState<ArticleSaved[]>();
  const [loading, setloading] = useState(false);
  const [articlesdispo, setarticles] = useState(false);
  const [meta, setmeta] = useState<{
    epuise: number;
    stock: number;
    epuise_today: number;
    all?: number;
  }>({
    epuise: 0,
    stock: 0,
    epuise_today: 0,
    all: 0,
  });
  const [ActiveStatus, setActiveStatus] = useState<
    'exhausted' | 'sold-out-today' | 'in-stock'
  >('in-stock');
  const [paramsArticle, setparamsArticle] = useState<getArticleParams>({});
  const {articles:artk} = useSelector((state: any) => state.articleReducer);
  console.log("artk",artk);


  const dispatch = useDispatch();

  useEffect(() => {
    let params: getArticleParams = {
      ...paramsArticle,
      nom: searchValue,
      pageSize: 1000,
      EtatStock: currentActiveToggle as 0 | 2 | 1 | -1 | undefined,
    };

    if (params.idFamily) {
      fetchSubFamily(params.idFamily);
    }
    fetchArticles(params);
  }, [searchValue, paramsArticle]);
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchValue(e.target.value);
  };
  useEffect(() => {
    fetchFamily();
  }, []);
  useEffect(() => {
    if (paramsArticle.idFamily) {
      setsubFamiles([]);
    }
  }, [paramsArticle.idFamily]);

  const fetchFamily = async () => {
    try {
      dispatch(
        getFamily((_) => {
          setfamilies(_ as Family[]);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const fetchSubFamily = async (familyiD: number) => {
    try {
      dispatch(
        getSubFamily(familyiD, (_) => {
          setsubFamiles([..._?.inverseIdSubFamilyNavigation]);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onSelect = (type: 'family' | 'subfamily', id: number) => {
    if (id != -1)
      type == 'family'
        ? setparamsArticle({
            ...paramsArticle,
            idFamily: id,
          })
        : setparamsArticle({
            ...paramsArticle,
            idSubFamily: id,
          });
    else {
      if (type == 'subfamily') {
        setparamsArticle({
          nom: searchValue,
          pageSize: 1000,
          idFamily: paramsArticle.idFamily,
        });
      } else {
        setparamsArticle({
          nom: searchValue,
          pageSize: 1000,
        });
      }
      setsubFamiles(undefined);
    }
  };
  const onSelectArticle = (id: number, checked: boolean) => {
    setselectedids((prevSelectedIds) => {
      if (checked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };
  useEffect(() => {
    if (currentActiveToggle != undefined) {
      fetchArticles({
        ...paramsArticle,
        EtatStock: currentActiveToggle as any,
      });
    }
  }, [currentActiveToggle]);

  const fetchArticles = (params?: getArticleParams) => {
    if (params?.EtatStock != undefined && params.EtatStock == -1) {
      delete params?.EtatStock;
    }
    try {
      setloading(true);
      dispatch(
        getArticle(
          {
            ...params,
            etat_de_stock: true,
            idEstablishment: Number(idEstablishment),

            page: 1,
            pageSize: 1000,
          } as getArticleParams,
          (a) => {
            setloading(false);

            setArticles((a as any)?.articles as any);
            if (currentActiveToggle == -1)
              setmeta({
                all: (a as any)?.articles?.length,
                epuise: (a as any)?.epuiseCount,
                stock: (a as any)?.enStockCount,
                epuise_today: (a as any)?.epuiseTodayCount,
              });
          },
        ),
      );
    } catch (error) {
      setloading(false);

      NotificationErrorProvider(error);
    }
  };
  const selectedCount = selectedids?.length ?? 0;

  const selectedArticles = (articles ?? []).filter((article) =>
    selectedids.includes(article.id),
  );
  const updateEtatDeStock = async () => {
    try {
      const response = await catalogueApi?.updateEtatDeStock({
        etat_de_stock: true,
        idEstablishment: Number(idEstablishment),
        etat:
          ActiveStatus == 'exhausted' ? 1 : ActiveStatus == 'in-stock' ? 0 : 2,
        articles: selectedArticles?.map((_) => _.id),
      });
      NotificationSuccessProvider('edit', 'Article');
      fetchArticles({
        ...paramsArticle,
        EtatStock: currentActiveToggle as any,
      });
      setselectedids([]);
      setShowModalEdit(false);
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onChangeToggle = (n: number) => {
    setcurrentActiveToggle(n);
    fetchArticles({
      ...paramsArticle,
      EtatStock: n as 0 | 1 | 2 | -1 | undefined,
    });
  };
  useEffect(() => {
    const params: getArticleParams = {
      ...paramsArticle,
      nom: searchValue?.trim(),
      EtatStock: currentActiveToggle as any,
      pageSize: 1000,
    };
    fetchArticles(params);
  }, [searchValue, paramsArticle, currentActiveToggle]);

  return (
    <div className="ListingEtatDeStockContainer">
      <Modal
        version="custom"
        content={
          <EditStockModalContent
            activeStatus={ActiveStatus}
            setActiveStatus={(s) => setActiveStatus(s)}
            slot={selectedCount}
            onClickCancel={() => setShowModalEdit(false)}
            onClickEdit={updateEtatDeStock}
          >
            {selectedArticles?.map((_) => (
              <EtatDeStockCard
                name={_.name}
                oldStatus={
                  _.etatStock == 0
                    ? 'in-stock'
                    : _.etatStock == 1
                      ? 'exhausted'
                      : 'sold-out-today'
                }
                currentStatus={ActiveStatus as any}
                onClickDelete={() => onSelectArticle(_.id, false)}
              />
            ))}
          </EditStockModalContent>
        }
        // title={""}
        visibility={showModalEdit}
        maxWidth={720}
        // height={480}
        close
      />

      <div className="content-container listing-articles-wrapper ">
        <div className="filter-section fl_col pd16-t-b  gp10">
          <div className="filter-wrapper fl_row jc_s ai_c pd24-r-l ">
            <StockFilterToggle
     
              counts={meta}
              active={currentActiveToggle}
              onchange={onChangeToggle}
            ></StockFilterToggle>
            <div
              className={`edit-stock-btn-div ${selectedCount > 0 ? 'enable-edit' : ''}`}
            >
              <button
                className={`edit-stock-btn ${selectedCount > 0 ? 'has-items' : ''}`}
                onClick={
                  selectedCount > 0 ? () => setShowModalEdit(true) : undefined
                }
              >
                Modifier état de stock •{' '}
                <span className="w-600 pd10-l">{selectedCount}</span>
              </button>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#F2F2F3' }}
            className="fl_row jc_s ai_c pd20-r-l pd6-t-b fl_wrap gp10"
          >
            <HeaderArticle
              hideAdd
              onSelect={onSelect}
              onSearch={onSearch}
              families={convertToOptions(families ?? [])}
              subFamilies={convertToOptions(subFamiles ?? [])}
              type="listing"
            />
            <CheckBox
              label="Sélectionner tous"
              boxType="squareBox"
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked && articles?.length) {
                  setselectedids(articles.map((article) => article.id));
                } else {
                  setselectedids([]);
                }
              }}
              isChecked={
                articles?.length ? selectedCount === articles.length : false
              }
            />

            {/* <CheckBox
              label="Sélectionner tous"
              boxType="squareBox"
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked && articles?.length > 0) {
                  setselectedids(articles.map((article) => article.id));
                } else {
                  setselectedids([]);
                }
              }}
              isChecked={
                selectedCount > 0 && selectedCount === articles?.length
              }
            /> */}
          </div>
        </div>
        <div className="listing-items pd12-t-b pd16-r-l">
          {!loading ? (
            articles && articles.length > 0 ? (
              articles.map((_) => (
                <ItemStock
                  key={_.id}
                  onClickCheck={(e) => {
                    onSelectArticle(_.id, e.target.checked);
                  }}
                  selected={selectedids.includes(_.id)}
                  name={_.name}
                  status={
                    _.etatStock === 0
                      ? 'in-stock'
                      : _.etatStock === 1
                        ? 'exhausted'
                        : 'sold-out-today'
                  }
                />
              ))
            ) : (
              <div className="no-articles-message">
                <div className="msg-wrapper">
                  <div className="fl_col gp10 ai_c">
                    <p className="p24 Independence-txt w-600 txt_center">
                      Aucun article trouvé
                    </p>
                    <img src={EmptyResultImg} alt="" />
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="fl_row w-full h-full jc_c w-full loading-msg">
              <div className="loading-wrapper">
                <span className="txt_center">Chargement en cours...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
