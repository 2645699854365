import React, { ChangeEvent, useEffect, useState } from 'react';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import ClearInputGrey from '../../assets/icons/ClearInputGrey.svg';
import InputField from './Input';
import OptionItem from './OptionItem';
import { languageData } from '../../i18n';

export interface Option {
  id: number;
  nom: string;
}

interface SelectInputProps {
  label?: string;
  name?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: string;
  isDisabled?: boolean;
  haserror?: boolean;
  errorMsg?: string;
  placeholder?: string;
  options: Option[];
  icons?: string[];
  target: 'country' | 'dropdown' | 'search' | 'reference' | 'reflags';
  onKeyUp?: () => void;
  onSelect?: (
    selectedValue: string,
    selectedIcon?: string,
    reference?: string,
  ) => void;
  reference?: string[];
  isRequired?: boolean;
  defaultValue?: string | number | undefined;
  value?: string | number | undefined;
  setidEntr?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setidEtab?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setInputValue?: any;
  inputValue?: any;
  filterItems?: any;
  setFilteredItems?: any;
  filteredItems?: any;
  setShowDropdown?: any;
  showDropdown?: any;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  type,
  style,
  isDisabled,
  placeholder,
  icons,
  options,
  onChange,
  target,
  onKeyUp,
  onSelect,
  haserror,
  errorMsg,
  reference,
  isRequired,
  defaultValue,
  setidEntr,
  setidEtab,
  value,
  setInputValue,
  inputValue,
  filterItems,
  setFilteredItems,
  filteredItems,
  setShowDropdown,
  showDropdown,
}) => {
  const [localHasError, setLocalHasError] = useState(haserror || false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setFilteredItems(options);
  }, [options]);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
    if (typeof defaultValue === 'string' && defaultValue === '') {
      setInputValue('');
    }
  }, [defaultValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setLocalHasError(haserror || false);
  }, [haserror]);

  const handleOptionClick = (
    optionId: number,
    optionNom: string,
    optionIcon?: string,
    ref?: string,
  ) => {
    setInputValue(optionId); // Store the ID instead of the name
    setShowDropdown(false);

    if (onSelect) {
      onSelect(optionNom, optionIcon, ref); // Pass the name to onSelect if needed
    }

    // Update external state if provided
    setidEntr && setidEntr(optionId);
    setidEtab && setidEtab(optionId);
  };

  // Find the selected option's name based on the inputValue (ID)
  const selectedOption = options.find((option) => option.id === inputValue);

  return (
    <div
      className={`InputFieldDropDown ${showDropdown ? 'DisplayList' : ''} fl_col gp8 ${target}_field ${
        localHasError ? 'haserror' : ''
      }`}
    >
      {showDropdown && (
        <div
          className="select-overlay"
          onClick={() => setShowDropdown(false)}
        ></div>
      )}

      <div className="inp-select-field">
        <div className="inp fl_col gp8">
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
          <InputField
            value={selectedOption ? selectedOption.nom : ''}
            onChange={onChange}
            name={name}
            type="text"
            placeholder={placeholder}
            label={label}
            style="form"
            isDisabled={isDisabled}
            haserror={localHasError}
            isRequired={isRequired}
            onKeyUp={onKeyUp}
          />
          <span className={`overideSelect ${selectedOption ? '' : 'Independence-txt'}`} onClick={() => setShowDropdown((prevState: any) => !prevState)}>
            {selectedOption ? selectedOption.nom : placeholder}
          </span>

          <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
            <ul className="fl_col gp10 pd10-t-b">
              {filteredItems.length > 0 ? (
                filteredItems.map((item: any) => (
                  <OptionItem
                    key={item.id}
                    target={target}
                    value={item.nom} // Display the name in the dropdown
                    icon={icons && icons[item.id]}
                    reference={reference && reference[item.id]}
                    onClick={() => handleOptionClick(item.id, item.nom)} // Pass both ID and name
                  />
                ))
              ) : isLoading ? (
                <li className="pd4-t-b pd10-r-l jc_c">
                  <div style={{ marginLeft: '-20px' }} className="fl_row jc_c">
                    <span className="loader-select DarkBlueGray-txt">
                      Chargement
                    </span>
                  </div>
                </li>
              ) : (
                <li className="pd4-t-b pd10-r-l jc_c p14">
                  {languageData.fr.card.noResult}
                </li>
              )}
            </ul>
          </div>
        </div>
        {inputValue && (
          <img
            src={ClearInputGrey}
            alt=""
            className="clear-select"
            onClick={() => {
              setInputValue('');
              setidEntr && setidEntr(undefined);
              setidEtab && setidEtab(undefined);
            }}
          />
        )}
      </div>
      {localHasError && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
    </div>
  );
};

export default SelectInput;
