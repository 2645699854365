import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import MarketsIconCircle from '../../../assets/icons/MarketsIconCircle.svg';
import EditGreyIcon from '../../../assets/icons/EditGreyIcon.svg';
import DeleteGreyIcon from '../../../assets/icons/DeleteGreyIcon.svg';
import CardContainer from '../../CardContainer';
import TableHeaderCustom from '../../../components/Table/TableHeaderCustom';
import Modal from '../../../components/Modal/Modal';
import { languageData } from '../../../i18n';
import { useState } from 'react';
import ModalAddRecompense from './AddRecompensePage';
import AddRecompensePage from './AddRecompensePage';
import EditRecompensePage from './EditRecompensePage';
import ModalDeleteRecompense from './ModalDeleteRecompense';
export default function RecompenseTable() {
  const [showAddRecompense, setShowAddRecompense] = useState(false);
  const [showEditRecompense, setShowEditRecompense] = useState(false);
  const [showRemoveRecompense, setShowDeleteRecompense] = useState(false);

  return (
    <div className="RecompenseTable fl_col gp10 h-full w-full">
      {!showAddRecompense && !showEditRecompense && (
        <>
          <TableHeaderCustom
            type={'add'}
            title="Points de fidélité sur le montant du panier"
            description="Gérer vos points de fidélités"
            largeTitle
            onClickAdd={() => setShowAddRecompense(true)}
            textAdd="Ajouter"
          />
          <CardContainer type="standard" fullHeight>
            <DesktopTableContainer>
              <Table className="Table TableEtablissement rd12">
                <Thead>
                  <Tr>
                    <Td type="text" value={'Désignation'} />
                    <Td
                      type="text"
                      value={'Récompense de bienvenue'}
                      width={15}
                    />
                    <Td
                      type="text"
                      value={'Récompense sur 1er achat'}
                      width={10}
                    />
                    <Td
                      type="text"
                      value={'Récompense de commandes'}
                      width={10}
                    />
                    <Td type="text" value={'Remise sur'} />

                    <Td type="text" value={'Points de vente'} />
                    <Td type="text" value={'Status'} />
                    <Td type="emptyRow" width={3} />
                    <Td type="emptyRow" width={3} />
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td type="text" value={'Five- Antony'} />
                    <Td type="pts" isActive value={'20'} width={15} />
                    <Td type="pts" isActive value={'20'} width={15} />
                    <Td type="pts" value={'80'} width={15} />
                    <Td type="text" value={'Article'} />
                    <Td
                      type="icon"
                      iconSrc={MarketsIconCircle}
                      // onClick={() => setShowModalPointsDeVentes(true)}
                    />
                    <Td
                      type="switcher"
                      statusToggle={'active'}
                      ischecked={true}
                      // onChange={() => alert('')}
                      width={15}
                    />
                    <Td
                      type="icon"
                      iconSrc={EditGreyIcon}
                      onClick={() => setShowEditRecompense(true)}
                      width={3}
                    />
                    <Td
                      type="icon"
                      iconSrc={DeleteGreyIcon}
                      onClick={() => setShowDeleteRecompense(true)}
                      width={3}
                    />
                  </Tr>
                </Tbody>
              </Table>
            </DesktopTableContainer>
          </CardContainer>
        </>
      )}
      {showAddRecompense && (
        <>
          <TableHeaderCustom
            type={'save'}
            title="Ajouter règle de récompense"
            largeTitle
            onClickAdd={() => setShowAddRecompense(false)}
            textAdd="save"
            onChange={() => alert('')}
            onClickBack = {() => setShowAddRecompense(false)}
          />
          <CardContainer type="standard" fullHeight>
            <AddRecompensePage />
          </CardContainer>
        </>
      )}
      {showEditRecompense && (
        <>
          <TableHeaderCustom
            type={'save'}
            title="Modifier règle de récompense"
            largeTitle
            onClickAdd={() => setShowEditRecompense(false)}
            textAdd="save"
            onChange={() => alert('')}
            onClickBack = {() => setShowEditRecompense(false)}
          />
          <CardContainer type="standard" fullHeight>
            <EditRecompensePage />
          </CardContainer>
        </>
      )}
      {showRemoveRecompense && <></>}

      <Modal
        className="ModalDeleteRecompense"
        version="custom"
        visibility={showRemoveRecompense}
        close
        maxWidth={640}
        content={
          <ModalDeleteRecompense
            onClickConfirm={() => alert('')}
            onClickClose={() => setShowDeleteRecompense(false)}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => setShowDeleteRecompense(false)}
      />
    </div>
  );
}
