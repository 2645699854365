import React from 'react';

/**
 * CharteGraphique component description.
 *
 * @CharteGraphique
 *
 */

interface CharteGraphiqueProps {
  title?: string;
}

const CharteGraphique: React.FC<CharteGraphiqueProps> = ({ title }) => {
  return <div className="CharteGraphique">
     
  </div>;
};

export default CharteGraphique;
  