import * as actionTypes from '../actions/actionTypes';

interface LoyaltyRulesState {
  loading: boolean;
  data: any; 
  error: string | null;
}

const initialState: LoyaltyRulesState = {
  loading: false,
  data: null,
  error: null,
};

const LoyaltyRulesList = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_LIST_LOYALTY_RULES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_LIST_LOYALTY_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionTypes.GET_LIST_LOYALTY_RULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default LoyaltyRulesList;
