import React, { useState } from 'react';
import ClockLight from '../../../../assets/icons/ClockLight.svg';

interface TimerSelectProps {
  onChange?: (value: string) => void;
  dropdown?: boolean;
  label?: string;
  value?: string;
}

const TimerSelect: React.FC<TimerSelectProps> = ({
  onChange,
  dropdown,
  label,
  value,
}) => {

  const timeOptions = Array.from({ length: 96 }, (_, index) => {
    const hours = String(Math.floor(index / 4)).padStart(2, '0');
    const minutes = String((index % 4) * 15).padStart(2, '0');
    return `${hours}:${minutes}`;
  });


  return (
    <div className="select-time">
      {dropdown ? (
        <div className="fl_col  gp10">
          <p className="p14 DarkBlueGray-txt w-700">{label}</p>
          <div className="Timerdropdown">
            <div className="timer-value">
              <select
                name="time"
                id="time"
                value={value}
                onChange={onChange as any}
              >
                {timeOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      ) : (
        <div className="TimerSelect">
          <div className="timer-value">
            <select
              name="time"
              id="time"
              value={value}
              onChange={onChange as any}
            >
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>

          <img src={ClockLight} alt="" />
        </div>
      )}
    </div>
  );
};

export default TimerSelect;
