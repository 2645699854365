export const fields = [
    {
      name: 'designation',
      label: 'Désignation',
      required: true,
    },
    {
      name: 'conversionAmount',
      label: 'Montant de conversion  (€)',
      type: 'number',
      required: true,
    },
    {
      name: 'loyaltyPointsAssociated',
      label: 'Nombre de points à gagner',
      type: 'number',
      required: true,
    },
    {
      name: 'isActive',
      label: 'isActive',
      type: 'checkbox',
    },
  ];
  export const fieldsUpdates = [
    {
      name: 'designation',
      label: 'Designation',
      required: true,
    },
    {
      name: 'conversionAmount',
      label: 'Montant de conversion  (€)',
      type: 'number',
      required: true,
    },
    {
      name: 'loyaltyPointsAssociated',
      label: 'Nombre de points à gagner',
      type: 'number',
      required: true,
    },
    {
      name: 'isActive',
      label: 'isActive',
      type: 'checkbox',
    },
  ];
