import React, { useState } from 'react';
import ButtonElement from '../../../components/button/button';
import { languageData } from '../../../i18n';
import ArticlesIconPrimary from '../../../assets/icons/ArticlesIconPrimary.svg';
import InputField from '../../../components/Input/Input';
import SwitcherCheckbox from '../../../components/Catalogue/SwitcherCheckbox';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface AddRecompensePropsPage {
  onClickSave?: () => void;
  onClickClose?: () => void;
  isActivated?: boolean;
  onChange?: () => void;
}

const AddRecompensePage: React.FC<AddRecompensePropsPage> = ({
  onClickSave,
  onClickClose,
  isActivated,
  onChange,
}) => {
  const [selectedItemOne, setSelectedItemOne] = useState<string>('euro');
  const [selectedItemTwo, setSelectedItemTwo] = useState<string>('euro');
  const [showArticles, setshowArticles] = useState(false);
  const [showPanier, setshowPanier] = useState(false);

  return (
    <div className="fl_col gp40 pd40-t-b">
      <div className="fl_row ai_c jc_s">
        <div className="fl_row jc_fe w-full">
          <div className="fl_row  w-full ai_e gp40">
            <div>
              <InputField
                isRequired={true}
                name="designation"
                type="number"
                label={'Désignation'}
                style="form"
                isDisabled={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fl_col gp10">
        <div className="fl_row gp40 tab-one">
          <div className=" fl_row fl-1 gp40">
            <div className="fl_col gp20">
              <SwitcherCheckbox
                // ischecked={""}
                onChange={onChange}
                label={'Récompense de bienvenue'}
                name="isActive"
              />
              <div className="pd20-r-l">
                <InputField
                  isRequired={true}
                  name="designation"
                  type="number"
                  label={'Nombre de points à gagner'}
                  style="form"
                  isDisabled={false}
                />
              </div>
            </div>
            <span className="diver-bar"></span>
            <div className="fl_col gp20">
              <SwitcherCheckbox
                // ischecked={""}
                onChange={onChange}
                label={'Récompense sur 1er achat'}
                name="isActive"
              />
              <div className="pd20-r-l">
                <InputField
                  isRequired={true}
                  name="designation"
                  type="number"
                  label={'Nombre de points à gagner'}
                  style="form"
                  isDisabled={false}
                />
              </div>
            </div>
            <span className="diver-bar"></span>

            <div className="fl_col gp20">
              <SwitcherCheckbox
                // ischecked={""}
                onChange={onChange}
                label={'Récompense sur nombre commande'}
                name="isActive"
              />
              <div className="fl_row gp20 pd20-r-l">
                <InputField
                  isRequired={true}
                  name="designation"
                  type="number"
                  label={'Nombre de visite requis'}
                  style="form"
                  isDisabled={false}
                />
                <InputField
                  isRequired={true}
                  name="designation"
                  type="number"
                  label={'Nombre de points à gagner'}
                  style="form"
                  isDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="mg20-t-b" />
        <div className="tab-two w-fit">
          <div className="fl_col gp40">
            <fieldset className="fl_row jc_s gp40">
              <legend>
                <div className="pd20-r-l">
                  <SwitcherCheckbox
                    // ischecked={""}
                    onChange={onChange}
                    label={'Options de remise'}
                    name="isActive"
                  />
                </div>
              </legend>
              <div className="fl_row gp20">
                <fieldset className="fl_row gp20">
                  <legend>
                    <div className="pd20-r-l">
                      <SwitcherCheckbox
                        // ischecked={""}
                        onChange={onChange}
                        label={'Remise sur les articles'}
                        name="isActive"
                      />
                    </div>
                  </legend>

                  <div className="fl_row gp40 ai_e fl-1">
                    <InputField
                      isRequired={true}
                      name="designation"
                      type="number"
                      label={'Nbr de points requis'}
                      style="form"
                      isDisabled={false}
                    />
                    <InputField
                      isRequired={true}
                      name="designation"
                      type="number"
                      label={'Valeur de remise'}
                      style="form"
                      isDisabled={false}
                    />
                    <div className="fl_row gp40 ai_e">
                      <div className="fl_col gp5">
                        <p className="p12 w-600 DarkBlueGray-txt label-field">
                          Type de remise
                        </p>
                        <div className="toggle-container">
                          <div className="item-type">
                            <input
                              type="radio"
                              id="toggle-euro-1"
                              name="toggle-1"
                              checked={selectedItemOne === 'euro'}
                              onChange={() => setSelectedItemOne('euro')}
                            />
                            <label
                              htmlFor="toggle-euro-1"
                              className="toggle-item"
                              id="euro"
                            >
                              €
                            </label>
                          </div>

                          <div className="item-type">
                            <input
                              type="radio"
                              id="toggle-percent-1"
                              name="toggle-1"
                              checked={selectedItemOne === 'percent'}
                              onChange={() => setSelectedItemOne('percent')}
                            />
                            <label
                              htmlFor="toggle-percent-1"
                              className="toggle-item"
                              id="percent"
                            >
                              %
                            </label>
                          </div>
                        </div>
                      </div>
                      <ButtonElement
                        reverse
                        buttonStyle="btn-white-primary"
                        type={'IconButton'}
                        text={'Articles'}
                        buttonSize={'large'}
                        onClick={onClickSave}
                        icon={ArticlesIconPrimary}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="fl_col gp20">
                <fieldset>
                  <legend>
                    <div className="pd20-r-l">
                      <SwitcherCheckbox
                        // ischecked={""}
                        onChange={onChange}
                        label={'Remise sur panier'}
                        name="isActive"
                      />
                    </div>
                  </legend>
                  <div className="fl_row gp40 ai_e">
                    <InputField
                      isRequired={true}
                      name="designation"
                      type="number"
                      label={'Nbr de points requis'}
                      style="form"
                      isDisabled={false}
                    />
                    <InputField
                      isRequired={true}
                      name="designation"
                      type="number"
                      label={'Valeur de remise'}
                      style="form"
                      isDisabled={false}
                    />
                    <div className="fl_col gp5">
                      <p className="p12 w-600 DarkBlueGray-txt label-field">
                        Type de remise
                      </p>
                      <div className="toggle-container">
                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-euro-2"
                            name="toggle-2"
                            checked={selectedItemTwo === 'euro'}
                            onChange={() => setSelectedItemTwo('euro')}
                          />
                          <label
                            htmlFor="toggle-euro-2"
                            className="toggle-item"
                            id="euro"
                          >
                            €
                          </label>
                        </div>

                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-percent-2"
                            name="toggle-2"
                            checked={selectedItemTwo === 'percent'}
                            onChange={() => setSelectedItemTwo('percent')}
                          />
                          <label
                            htmlFor="toggle-percent-2"
                            className="toggle-item"
                            id="percent"
                          >
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecompensePage;
