import React from 'react';
import ArticleDishIconGrey from '../../../../assets/icons/ArticleDishIconGrey.svg';
import MenusBookIconGrey from '../../../../assets/icons/MenusBookIconGrey.svg';
import CategoryIconGrey from '../../../../assets/icons/CategoryIconGrey.svg';
import ArrowLeftIconBlack from '../../../../assets/icons/ArrowLeftIconBlack.svg';
import CompanyIconGrey from '../../../../assets/icons/CompanyIconGrey.svg';

export default function HeaderEtatDeStock({
  activeTab,
  onChangeTab,
  onBack,
  hideoptions,
  isEtab,
}: {
  activeTab: string;
  onChangeTab: (tab: string) => void;
  onBack?: () => void;
  hideoptions?: boolean;
  isEtab?: boolean;
}) {

  const idEtablissement = localStorage.getItem('EtabNameEtablishement');
  const EntrNameEtablishement = localStorage.getItem('EntrNameEtablishement');



  return (
    <div className="HeaderEtatDeStock">
      <div className="header-container">
        <div className="header-wrapper">
          <div className="tab-switcher-container fl_col gp40 ">
            <div className="fl_row jc_s pd16 tab-switcher-wrapper">
              <div className="nav-btn fl_row gp10 c-ph ">
                {!isEtab && (
                  <img src={ArrowLeftIconBlack} alt="" onClick={onBack} />
                )}

                <p className="p20 w-700 OxfordBlue02-txt cap txt-nowrap">
                  état des stocks
                </p>
              </div>

              {!hideoptions && (
                <div className="company-switcher fl_row gp20">
                  <div className="fl_row gp15 ai_c c-ph">
                    <img src={CompanyIconGrey} alt="" />
                    <p className="p16 OxfordBlue02-txt w-700 cap">
                      {EntrNameEtablishement}
                    </p>
                  </div>
                  <div className="fl_row gp15 ai_c c-ph active-company">
                    <p className="p16 OxfordBlue02-txt w-700 cap">
                      {idEtablissement}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {!hideoptions && (
              <div className="tab-switcher">
                <div className="tab-wrapper fl_row ai_c">
                  <div
                    className={`fl_row ai_c pd16-r-l pd10-t-b gp10  ${activeTab == 'article' ? 'active-menu' : ''}`}
                    onClick={() => onChangeTab('article')}
                  >
                    <img src={ArticleDishIconGrey} alt="" />
                    <p className="p16 Nevada-txt w-600 cap">articles</p>
                  </div>
                  <div
                    className={`fl_row ai_c pd16-r-l pd10-t-b gp10 ${activeTab == 'menu' ? 'active-menu' : ''}`}
                    onClick={() => onChangeTab('menu')}
                  >
                    <img src={MenusBookIconGrey} alt="" />
                    <p className="p16 Nevada-txt w-600 cap"> menus</p>
                  </div>
                  <div
                    className={`fl_row ai_c pd16-r-l pd10-t-b gp10 ${activeTab == 'category' ? 'active-menu' : ''}`}
                    onClick={() => onChangeTab('category')}
                  >
                    <img src={CategoryIconGrey} alt="" />
                    <p className="p16 Nevada-txt w-600 cap">catégories</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
