  import React from 'react';
  import ButtonElement from '../../../components/button/button';
  import CartCheckboxChildElement from '../../../components/Carts/CartCheckboxChildElement';
  import CartCheckboxParentElement from '../../../components/Carts/CartCheckboxParentElement';
  import { languageData } from '../../../i18n';
  import CloseBtnPrimary from '../../../assets/icons/CloseBtnPrimary.svg';
  import InputField from '../../../components/Input/Input';
  import CheckBox from '../../../components/Catalogue/Checkbox';

  /**
   * Modal component description.
   *
   * @Modal
   *
   */

  interface ModalDeletePointFideliteProps {
    onClickConfirm?: () => void;
    onClickClose?: () => void;
    isActivated?: boolean;
    onChange?: () => void;
  }

  const ModalDeletePointFidelite: React.FC<ModalDeletePointFideliteProps> = ({
    onClickConfirm,
    onClickClose,
    isActivated,
    onChange,
  }) => {
    return (
      <div className="ModalDeletePointFidelite fl_col gp40">
        <div className="fl_col gp10">
          <p className="p20 txt-nowrap w-600">
            Êtes-vous sûr(e) de vouloir supprimer ?
          </p>
          <p className="p14">
            Cette action est irréversible et pourrait avoir un impact sur d'autres
            parties où cet article est utilisé.
          </p>
        </div>
        <div className="ModalFooter">
          <div className="wrapperFooter">
            <div className="fl_row gp12">
              <div className="fl-1">
                <ButtonElement
                  type={'TextButton'}
                  text={languageData.fr.Modal.cancel}
                  buttonSize={'small'}
                  onClick={onClickClose}
                />
              </div>
              <div className="fl-3">
                <ButtonElement
                  type={'TextButton'}
                  text="Supprimer"
                  buttonSize={'small'}
                  buttonStyle="btn-red"
                  onClick={onClickConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default ModalDeletePointFidelite;
