import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import {
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  fetchLoyaltyStatusFailure,
  fetchLoyaltyStatusSuccess,
  activateDeactivateLoyaltyFailure,
  activateDeactivateLoyaltySuccess,
  getListLoyaltyRulesFailure,
  getListLoyaltyRulesSuccess,
  deleteLoyaltyRuleSuccess,
  deleteLoyaltyRuleFailure,
  updateLoyaltyRuleFailure,
  updateLoyaltyRuleSuccess,
  fetchEstablishmentsFailure,
  fetchEstablishmentsSuccess,
  affectLoyaltyRuleToEstablishmentsSuccess,
  affectLoyaltyRuleToEstablishmentsFailure,
} from '../actions/actions';
import apiHandlers from '../../services/apiHandlers/entrepriseApi';
import toast from 'react-hot-toast';
import { serviceMarketingModels } from '../../models/services/module.serviceMarketingModels';

export function* MarketingSaga(action: { type: string }) {
  try {
    const response: AxiosResponse =
      yield apiHandlers.getListCompaniesForMarketingModule();

    if (response?.data) {
      yield put(fetchCompaniesSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(fetchCompaniesFailure(error.message));
  }
}
// Define the type for the action
type FetchLoyaltyStatusAction = {
  type: string;
  payload: { companyId: number };
};

// Define the type for the API response
type LoyaltyStatusResponse = {
  result: boolean;
};

export function* fetchLoyaltyStatusSaga(action: FetchLoyaltyStatusAction) {
  try {
    const response: AxiosResponse<LoyaltyStatusResponse> = yield call(
      apiHandlers.fetchLoyaltyStatus,
      action.payload.companyId,
    );
    if (response?.data) {
      yield put(fetchLoyaltyStatusSuccess(response.data.result));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(fetchLoyaltyStatusFailure(errorMessage));
  }
}
export function* activateDeactivateLoyaltySaga(action: {
  type: string;
  payload: { companyId: number; IsLoyaltyCentralized: boolean };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.activateDeactivateLoyalty,
      action.payload.companyId,
      action.payload.IsLoyaltyCentralized,
    );
    if (response?.data) {
      yield put(activateDeactivateLoyaltySuccess(response.data.result));
      toast.success(
        "Vous avez modifié l'état de la centralisation des points de fidélité",
      );
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(activateDeactivateLoyaltyFailure(errorMessage));
  }
}

export function* createLoyaltyRuleSaga(action: {
  type: string;
  payload: {
    companyId: number;
    body: serviceMarketingModels.LoyaltyRulePayload;
    _?: () => void;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.createLoyaltyRule,
      action.payload.companyId,
      action.payload.body,
    );
    if (response?.data) {
      toast.success('Règle de fidélité liée au panier créée avec succès !');
      action.payload._ && action.payload._?.();
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    toast.error(
      'Une règle de fidélité a déjà été créée avec cette désignation. Veuillez en choisir une autre !',
    );
  }
}

export function* getLoyaltyRulesSaga(action: {
  type: string;
  payload: { companyId: number };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.getLoyaltyRules,
      action.payload.companyId,
    );
    if (response?.data) {
      yield put(getListLoyaltyRulesSuccess(response.data.result));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(getListLoyaltyRulesFailure(errorMessage));
  }
}

export function* deleteLoyaltyRuleSaga(action: {
  type: string;
  payload: {
    loyaltyRuleId: number;
    _?: () => void;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.deleteLoyaltyRule,
      action.payload.loyaltyRuleId,
    );
    if (response?.data) {
      toast.success('Règle de fidélité a été supprimés avec succès.');
      yield put(deleteLoyaltyRuleSuccess(response.data.result));
      action.payload._ && action.payload._?.();
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(deleteLoyaltyRuleFailure(errorMessage));
    toast.error(errorMessage);
  }
}

export function* updateLoyaltyRuleSaga(action: {
  type: string;
  payload: {
    loyaltyRuleId: number;
    body: any;
    _?: () => void;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.updateLoyaltyRule,
      action.payload.loyaltyRuleId,
      action.payload.body,
    );
    if (response?.data) {
      yield put(updateLoyaltyRuleSuccess(response.data.result));
      toast.success(
        'Modification de la règle de fidélité liée au panier effectuée avec succès !',
      );
      action.payload._ && action.payload._?.();
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(updateLoyaltyRuleFailure(errorMessage));
    toast.error(
      'Une erreur est survenue lors de la modification de la règle de fidélité liée au panier',
    );
  }
}

export function* getListPointdeventes(action: {
  type: string;
  payload: { companyId: number; loyaltyRuleId: number };
}) {
  try {
    const response: AxiosResponse = yield call(
      apiHandlers.getlistEstablishments,
      action.payload.companyId,
      action.payload.loyaltyRuleId,
    );
    if (response?.data) {
      yield put(fetchEstablishmentsSuccess(response.data.result));
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(fetchEstablishmentsFailure(errorMessage));
  }
}

export function* affectLoyaltyRuleToEstablishmentsSaga(action: any) {
  try {
    const { loyaltyRuleId, establishmentIds, forcedToCrush, _ } =
      action.payload;
    const response: AxiosResponse = yield call(
      apiHandlers.affectLoyaltyRuleToEstablishmentsApi,
      loyaltyRuleId,
      establishmentIds,
      forcedToCrush,
    );
    if (response?.data) {
      yield put(affectLoyaltyRuleToEstablishmentsSuccess(response.data.result));
      toast.success(' Les points de ventes ont été affectés avec succès ! ');
      _ && _?.();
    }
  } catch (error: any) {
    yield put(
      affectLoyaltyRuleToEstablishmentsFailure(
        error?.response?.data?.failureResponse,
      ),
    );
  }
}
