import * as actionTypes from '../actions/actionTypes';

interface LoyaltyState {
  loading: boolean;
  result: boolean | null;
  error: string | null;
}

const initialState: LoyaltyState = {
  loading: false,
  result: null,
  error: null,
};

const loyaltyActivatedesactivatedReducer = (state = initialState, action: any): LoyaltyState => {
  switch (action.type) {
    case actionTypes.ACTIVATE_DEACTIVATE_LOYALTY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ACTIVATE_DEACTIVATE_LOYALTY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
      };
    case actionTypes.ACTIVATE_DEACTIVATE_LOYALTY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default loyaltyActivatedesactivatedReducer;
