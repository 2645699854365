import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  createOnlineOrderDetailsFaild,
  createOnlineOrderDetailsSuccess,
  fetchOnlineOrderDetailsFailure,
  fetchOnlineOrderDetailsSuccess,
} from '../actions/actions';
import onlineOrderApi from '../../services/apiHandlers/onlineOrder';

export function* onlineOrderDetailsSaga(action: {
  type: string;
  payload: { EstablishmentId: number };
}) {
  const { EstablishmentId } = action.payload;
  try {
    const response: AxiosResponse =
      yield onlineOrderApi.getOnlineOrdersDetails(EstablishmentId);
    if (response?.data) {
      yield put(fetchOnlineOrderDetailsSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(fetchOnlineOrderDetailsFailure(error.message));
  }
}
//function to retry api call
export function* retryApiCall(
  apiCall: any,
  payload: any,
  retries: number
): Generator<any, any, any> {
  try {
    return yield apiCall(payload);
  } catch (error) {
    if (retries > 0) {
      return yield retryApiCall(apiCall, payload, retries - 1); 
    }
    throw error;
  }
}


export function* CreateOnlineOrderDetailsSaga(action: {
  type: string;
  payload: any;
  _?: () => void;
}) {
  try {
    const response: AxiosResponse = yield retryApiCall(
      onlineOrderApi.addOnlineOrders,
      action.payload,
      1 // Number of retries
    );
    if (response?.data) {
      yield put(createOnlineOrderDetailsSuccess(response.data.result));
      action?._ && action?._();
    }
  } catch (error: any) {
    yield put(createOnlineOrderDetailsFaild(error.message));
  }
}
