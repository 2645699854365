import * as actionTypes from '../actions/actionTypes';

const initialState = {
  createOnlineOrderDetails: null,
  loading: false,
  error: null,
};

const createOnlineOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CREATE_ONLINE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.CREATE_ONLINE_ORDER_SUCCESS:
      return {
        ...state,
        createOnlineOrderDetails: action.payload,
        loading: false,
      };
    case actionTypes.CREATE_ONLINE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.CLEAR_REDUCER:
      return {
        ...state,
        loading: false,
        createOnlineOrderDetails: null,
        error: false,
      };
    default:
      return state;
  }
};

export default createOnlineOrderReducer;
