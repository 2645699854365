import axiosInstance, { BaseUrl } from './apiGate';

const onlineOrderApi = {
  addOnlineOrders: async (body: serviceChartCompanyModels.ModeDePayment) => {
    try {
      const response = await axiosInstance.post(BaseUrl + `/OnlineOrder`, body);

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getOnlineOrdersDetails: async (establishmentId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/OnlineOrder?EstablishmentId=${establishmentId}`,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default onlineOrderApi;
