import MarketingOptionCard from '../MarketingOptionCard';
import FidelityOptionCard from '../../../assets/icons/FidelityOptionCard.svg';
import RecompenseIconCard from '../../../assets/icons/RecompenseIconCard.svg';
import FideliteTable from './FideliteTable';
import { useState } from 'react';
import TableHeaderCustom from '../../../components/Table/TableHeaderCustom';
import CardContainer from '../../CardContainer';
import { ClearsetTheInputValue } from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import RecompenseTable from '../SystemeRecompense/RecompenseTable';

export default function SelectFidelitePanierOption({
  IsCentrelaisedCompany,
  handleToggle,
  setShowSelectFidelitePanierOption,
  setShowMarketingOptionCard,
  setInputValue,
}: {
  IsCentrelaisedCompany: boolean;
  handleToggle: () => void;
  setShowSelectFidelitePanierOption: any;
  setShowMarketingOptionCard: any;
  setInputValue: any;
}) {
  const [showRecompenseTable, setShowRecompenseTable] = useState(false);
  const [showFideliteTable, setShowFideliteTable] = useState(false);
  const handleShowTable = () => setShowFideliteTable(true);
  const handleRecompenseTable = () => setShowRecompenseTable(true);

  const dispatch = useDispatch();
  if (showFideliteTable) {
    return (
      <div className="select-fidelite-panier-option h-full relative">
        <FideliteTable setShowFideliteTable={setShowFideliteTable} />
      </div>
    );
  }
  if (showRecompenseTable) {
    return (
      <div className='h-full'>
        <RecompenseTable />
      </div>
    );
  }

  return (
    <div className="fl_col gp10 h-full w-full">
      <TableHeaderCustom
        type={'title'}
        title="Engager vos clients"
        largeTitle
        toggleSwitcher
        onChange={handleToggle}
        ischecked={IsCentrelaisedCompany}
        toggleLabel="Centralisation point de fidélité"
        onClickBack={() => {
          setShowMarketingOptionCard(true);
          setShowSelectFidelitePanierOption(false);
          dispatch(ClearsetTheInputValue());
          setInputValue('');
        }}
      />
      <CardContainer type="standard" fullHeight>
        <div className="select-fidelite-panier-option h-full relative">
          <div className="cards-fidelite fl_row gp24">
            <MarketingOptionCard
              style="grid"
              title="Points de Fidélité Panier"
              description="Vous pouvez attribuer des points de fidélité selon des règles basées sur le montant du panier, avec un nombre de points spécifique par palier de dépense."
              icon={FidelityOptionCard}
              onClick={handleShowTable}
            />
            <MarketingOptionCard
              style="grid"
              title="Système de Récompense"
              description="Attribuez automatiquement des points de fidélité à l'inscription, au premier achat ou après plusieurs visites, et personnalisez les remises selon les points accumulés."
              icon={RecompenseIconCard}
              onClick={handleRecompenseTable}
            />
          </div>
        </div>
      </CardContainer>
    </div>
  );
}
