import React, { useEffect, useState } from 'react';
import CharteGraphique from './CommandeEnLigneTabs/CharteGraphique';
import ModeDePaiement from './CommandeEnLigneTabs/ModeDePaiement';
import ModeLivraison from './CommandeEnLigneTabs/ModeLivraison';
import PlageHoraire from './CommandeEnLigneTabs/PlageHoraire';
import SaveIconWhiteBtn from '../../../assets/icons/SaveIconWhiteBtn.svg';
import ErrorIconCircleLightRed from '../../../assets/icons/ErrorIconCircleLightRed.svg';
import CloseIconWhite from '../../../assets/icons/CloseIconWhite.svg';
import SwitcherCheckbox from '../../../components/Catalogue/SwitcherCheckbox';
import {
  createOnlineOrderDetailsRequest,
  fetchOnlineOrderDetailsRequest,
  clearReducer,
} from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import Toast from '../../../components/Toast/Toast';

const initialState = {
  establishmentId: 0,
  onlineOrderActive: true,
  isStripeActive: true,
  supportOnSpot: true,
  useStripePlatformAccount: true,
  stripePrivateApiKey: '',
  stripePublicApiKey: '',
  stripepaymentfee: null,
  supportsOnSpot: true,
  supportsPickUp: true,
  supportsDeliver: true,
  averageRecoveryTime: '',
  averageDeliveryTime: '',
  cartThreshold: null,
  deliveryRadius: null,
  workHours: [],
};

export default function CommandeEnLigneEtablissement() {
  const [activeTab, setActiveTab] = useState('Mode de paiement');
  const [state, setState] =
    useState<serviceChartCompanyModels.ModeDePayment>(initialState);
  const dispatch = useDispatch();
  const { onlineOrderDetails, loading, error } = useSelector(
    (state: {
      onlineOrder: {
        onlineOrderDetails: serviceChartCompanyModels.ModeDePayment;
        loading: boolean;
        error: string;
      };
    }) => state.onlineOrder,
  );
  const {
    createOnlineOrderDetails,
    loading: createLoading,
    error: createError,
  } = useSelector((state: any) => state.createOnlineOrder);
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );

  const handleResponse = () => {
    if (
      createOnlineOrderDetails === 'Success' ||
      createOnlineOrderDetails === null
    ) {
      return {
        code: 200,
        result: 'Success',
      };
    }
    if (
      !createOnlineOrderDetails?.paiementMethodsPassed ||
      !createOnlineOrderDetails?.workHoursPassed ||
      !createOnlineOrderDetails?.deliveryMethodsPassed
    ) {
      const failedCondition = !createOnlineOrderDetails?.paiementMethodsPassed
        ? 'Mode de paiement'
        : !createOnlineOrderDetails?.workHoursPassed
          ? 'Plage horaire'
          : 'Mode de livraison';

      return {
        code: 200,
        result: `${failedCondition} est obligatoire`,
      };
    }
  };

  const result = handleResponse();
  useEffect(() => {
    const fetchOnlineOrder = async () => {
      try {
        dispatch(fetchOnlineOrderDetailsRequest(activeTabContent?.id));
        setState(onlineOrderDetails);
        localStorage.setItem(
          'settingsFormData',
          JSON.stringify(onlineOrderDetails),
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchOnlineOrder();
  }, []);

  const postOnlineOrderDetails = () => {
    dispatch(
      createOnlineOrderDetailsRequest(state, () => {
        dispatch(fetchOnlineOrderDetailsRequest(activeTabContent?.id));
      }),
    );
  };

  useEffect(() => {
    const savedData = localStorage.getItem('settingsFormData');
    if (savedData) {
      setState(JSON.parse(savedData));
    } else {
      dispatch(fetchOnlineOrderDetailsRequest(activeTabContent?.id));
    }
  }, [dispatch]);

  const handleInputChange = (field: keyof typeof state, value: any) => {
    setState((prevState) => {
      const updatedState = { ...prevState, [field]: value };
      localStorage.setItem('settingsFormData', JSON.stringify(updatedState));
      return updatedState;
    });
  };
  // Handle switch toggles
  const handleSwitcherChange = (field: keyof typeof state) => {
    handleInputChange(field, !state[field]);
  };
  useEffect(() => {
    if (onlineOrderDetails) {
      setState(onlineOrderDetails);
      localStorage.setItem(
        'settingsFormData',
        JSON.stringify(onlineOrderDetails),
      );
    }
  }, [onlineOrderDetails]);

  // Handle work hour changes
  const handleWorkHourChange = (index: number, day: number) => {
    setState((prevState) => {
      const updatedWorkHours = prevState?.workHours?.map((hour, i) =>
        i === index
          ? {
              ...hour,
              days: hour?.days?.includes(day)
                ? hour?.days?.filter((d: number) => d !== day)
                : [...hour?.days, day],
            }
          : hour,
      );

      const updatedState = { ...prevState, workHours: updatedWorkHours };
      localStorage.setItem('settingsFormData', JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const handleTimeChange = (index: number, field: string, value: any) => {
    setState((prevState) => {
      const updatedWorkHours = prevState?.workHours?.map((hour, i) =>
        i === index ? { ...hour, [field]: value } : hour,
      );
      const updatedState = { ...prevState, workHours: updatedWorkHours };
      localStorage.setItem('settingsFormData', JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const handleAddWorkHour = () => {
    setState((prevState) => {
      const updatedWorkHours = [
        ...prevState?.workHours,
        {
          establishementId: activeTabContent?.id,
          index: prevState?.workHours?.length + 1,
          beginTime: '',
          endTime: '',
          days: [],
        },
      ];

      const updatedState = { ...prevState, workHours: updatedWorkHours };
      localStorage.setItem('settingsFormData', JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const handleDeleteWorkHour = (index: number) => {
    setState((prevState) => {
      const updatedWorkHours = prevState?.workHours?.filter(
        (_, i) => i !== index,
      );
      const updatedState = { ...prevState, workHours: updatedWorkHours };
      localStorage.setItem('settingsFormData', JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Mode de paiement':
        return (
          <ModeDePaiement
            state={state}
            handleInputChange={handleInputChange}
            handleSwitcherChange={handleSwitcherChange}
          />
        );
      case 'Plage horaire':
        return (
          <PlageHoraire
            workHours={state?.workHours}
            handleTimeChange={handleTimeChange}
            onChange={handleWorkHourChange}
            onAdd={handleAddWorkHour}
            onDelete={handleDeleteWorkHour}
            loading={loading}
          />
        );
      case 'Charte Graphique':
        return <CharteGraphique />;
      case 'Mode de livraison':
        return (
          <ModeLivraison
            state={state}
            handleInputChange={handleInputChange}
            handleSwitcherChange={handleSwitcherChange}
          />
        );
      default:
        return null;
    }
  };

  const renderTabContentHeaderText = () => {
    switch (activeTab) {
      case 'Mode de paiement':
        return 'Mode de paiement';
      case 'Plage horaire':
        return 'Plage horaire';
      // case 'Charte Graphique':
      //   return 'Charte Graphique';
      case 'Mode de livraison':
        return 'Mode de livraison';
      default:
        return null;
    }
  };

  return (
    <div className="CommandeEnLigne h-full">
      <div className="section-wrapper fl_row gp20 h-full">
        <div className="tabs-list fl_col gp20">
          <div className="fl_row jc_fe">
            <SwitcherCheckbox
              ischecked={state?.onlineOrderActive}
              onChange={() => handleSwitcherChange('onlineOrderActive')}
              label={state?.onlineOrderActive ? 'Active' : 'Desactive'}
            />
          </div>
          <ul className="fl_col gp8">
            {[
              'Mode de paiement',
              'Plage horaire',
              'Mode de livraison',
              // 'Charte Graphique',
            ].map((tab) => (
              <li
                key={tab}
                className={tab === activeTab ? 'active-tab' : ''}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        <div className="tabs-content fl-1 pd12 rd8">
          <div className="content-wrapper fl_col gp20">
            <div className="fl_row jc_fe">
              <button className="save-btn" onClick={postOnlineOrderDetails}>
                <img src={SaveIconWhiteBtn} alt="" />
                Sauvegarder
              </button>
            </div>
            {result?.result === 'Success' ? null : (
              <div className="fl_row ai_c jc_s  Carnelian-bg rd12 pd20-r-l pd10-t-b">
                <div className="fl_row gp10 ai_c">
                  <img src={ErrorIconCircleLightRed} alt="" />
                  <p className="p16  white-txt ">{result?.result}</p>
                </div>
                <div
                  onClick={() => dispatch(clearReducer())}
                  className="pd8 c-p"
                >
                  <img src={CloseIconWhite} alt="" />
                </div>
              </div>
            )}
            <div className="header-tab rd8">
              <p className="p18 w-700 white-txt">
                {renderTabContentHeaderText()}
              </p>
            </div>
            <div className="pd20-r-l h-full">{renderTabContent()}</div>
            <Toast
              type="error"
              description="Une erreur est survenue"
              isVisible={createError ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
