import React from 'react';
import { getMethodIcon } from '../../../utils/catalogueHelper/levelHelper';
import { purshaseMethod as purshaseMethodType } from '../../../models/services/module.catalogue';
import { languageData } from '../../../i18n';
/**
 * PaymentMode component description.
 *
 * @PaymentMode
 *
 */
interface PaymentModeProps {
  onClick?: (purshaseMethod: purshaseMethodType) => void;
  purshaseMethods?: purshaseMethodType[];
  activeMethods?: purshaseMethodType[];
  withName?: boolean;
  disabledMode?: boolean;
  hasError?: boolean;
  hasError2?:boolean
}
const PaymentMode: React.FC<PaymentModeProps> = ({
  purshaseMethods,
  activeMethods,
  onClick,
  withName,
  disabledMode,
  hasError,
  hasError2
}) => {
  console.log({ activeMethods });
  return (
    <div className="PaymentMode">
      <div className="payment-wrapper fl_row gp1">
        {purshaseMethods &&
          purshaseMethods?.map((_, __) => (
            <div
              className={`card-payment fl-1 fl_row gp5 
                ${
                  activeMethods &&
                  activeMethods?.findIndex(
                    (a) =>
                      (withName ? _.designation?.toLowerCase() : _.id) ==
                      (withName
                        ? a.designation?.toLowerCase()
                        : a.typeOrderId
                          ? a.typeOrderId
                          : a.id),
                  ) > -1
                    ? 'active white-txt EerieBlack2-bg '
                    : 'EerieBlack2-txt Cultured-bg'
                } surplace`}
              onClick={() => onClick && !disabledMode && onClick(_)}
            >
              <img src={getMethodIcon(_.designation)} alt="" />
              <p className="p14">{_.designation}</p>
            </div>
          ))}

        {/*   <div className={`card-payment fl-1 fl_row gp5 ${aemporter ? "active white-txt EerieBlack2-bg " : "EerieBlack2-txt Cultured-bg"} aemporter`} onClick={onClickAemporter}>
                    <img src={aemporterIcons} alt="" />
                    <p className="p14">A Emporter</p>
                </div>
                <div className={`card-payment fl-1 fl_row gp5 ${livraison ? "active white-txt EerieBlack2-bg " : "EerieBlack2-txt Cultured-bg"} livraison`} onClick={onClickLivraison}>
                    <img src={livraisonIcons} alt="" />
                    <p className="p14">Livraison</p>
                </div>
                <div className={`card-payment fl-1 fl_row gp5 ${drive ? "active white-txt EerieBlack2-bg " : "EerieBlack2-txt Cultured-bg"} drive`} onClick={onClickDrive}>
                    <img src={driveIcons} alt="" />
                    <p className="p14">Drive</p>
                </div> */}
      </div>
      {activeMethods?.length == 0 && hasError && (
        <p className="p14 pd10 ErrorField-txt">
          {languageData.fr.errorControl.purshaseMethod}
        </p>
      )}
        {activeMethods?.length == 0 && hasError2 && (
        <p className="p14 pd10 ErrorField-txt">
          {languageData.fr.errorControl.purshaseMethod2}
        </p>
      )}
     
    </div>
  );
};

export default PaymentMode;
