import React from 'react';
import RightArrowCirclePrimary from '../../assets/icons/RightArrowCirclePrimary.svg';
/**
 * MarketingOptionCard component description.
 *
 * @MarketingOptionCard
 *
 */

interface MarketingOptionCardProps {
  title?: string;
  description?: string;
  icon?: string;
  onClick?: () => void;
  style: 'large' | 'grid';

}

const MarketingOptionCard: React.FC<MarketingOptionCardProps> = ({
  title,
  description,
  icon,
  onClick,
  style,
}) => {
  const getCardStyle = () => {
    switch (style) {
      case 'large':
        return (
          <div className="card-wrapper fl_col jc_s gp10 h-full">
            <div className="card-content fl_col gp15">
              <p className="p30 DarkJungleGreen-txt w-700">{title}</p>
              <p className="p16 Independence-txt">{description}</p>
            </div>
            <img
              src={RightArrowCirclePrimary}
              alt=""
              width={'42px'}
              className="icon-nav"
            />
            <img src={icon} alt="" width={'200px'} className="icon-card" />
          </div>
        );

      case 'grid':
        return (
          <div className="card-wrapper fl_col jc_s gp10 h-full">
            <div className="card-content fl_col gp15">
              <p className="p20 DarkJungleGreen-txt w-700">{title}</p>
              <p className="p14 Independence-txt">{description}</p>
            </div>
            <img
              src={RightArrowCirclePrimary}
              alt=""
              width={'42px'}
              className="icon-nav"
            />
            <img src={icon} alt="" width={'200px'} className="icon-card" />
          </div>
        );
    }
  };
  return (
    <div className={`MarketingOptionCard-${style}`} onClick={onClick}>
      {getCardStyle()}
    </div>
  );
};

export default MarketingOptionCard;
