import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  companies: [],
  singleCompany: null,
  inputtheValue: null,
  error: '',
};

const companiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload.listCompanies,
        singleCompany: action.payload,
        error: '',
      };
    case actionTypes.SET_INPUT_VALUE:
      return {
        ...state,
        inputtheValue: action.payload,
      };
    case actionTypes.CLEAR_SET_INPUT_VALUE:
      return {
        ...state,
        inputtheValue: null,
      };
    case actionTypes.FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        companies: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companiesReducer;
