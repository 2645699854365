import CardContainer from '../CardContainer';
import SelectOptionMarketing from './SelectOptionMarketing';

export default function Marketing() {
  return (
    <div className="Marketing h-full">
      <SelectOptionMarketing />
    </div>
  );
}
