import { config } from '../../config';
import {
  carteConfig,
  filtredPvConfig,
  modalData,
} from '../../models/services/module.cart';
import {
  ArticleAllergen,
  Badges,
  Family,
  GeneralCodeTva,
  MenuPayload,
  Tags,
  UploadFolder,
  addArticlePayload,
  getArticleParams,
  levelPayload,
  screen,
} from '../../models/services/module.catalogue';
import { CategoryPayLoad } from '../../models/services/module.category';
import { removeDuplicates } from '../../utils/catalogueHelper/articleHelper';
import { authAxios, authUploadAxios } from './apiGate';

export const catalogueApi = {
  getBadges: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Badge?idEntreprise=${idCompany}`,
    );
  },
  postBadge: (Badge: Badges | undefined) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Badge`, {
      ...Badge,
      idEntreprise: idCompany,
    });
  },
  editBadge: (Badge: Badges) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Badge`, {
      ...Badge,
      idEntreprise: idCompany,
    });
  },
  deleteBadge: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Badge/${id}?idEntreprise=${idCompany}`,
    );
  },
  getBadgeById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/GetBadgeById/${id}?idEntreprise=${idCompany}`,
    );
  },
  //Tags
  getTags: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(`${config.api_baseUrl}/Tag?idEntreprise=${idCompany}`);
  },
  postTags: (tag: Tags) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Tag`, {
      ...tag,
      idEntreprise: idCompany,
    });
  },
  deleteTag: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Tag/${id}?idEntreprise=${idCompany}`,
    );
  },
  //Family
  getFamily: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/family?idEntreprise=${idCompany}`,
    );
  },
  getSubFamily: (idOfFamily?: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Family${idOfFamily ? '/GetFamilyByIdWithInclude/' + idOfFamily : '/GetFamilyWithInclude'}?idEntreprise=${idCompany}`,
    );
  },
  saveSubFamily: (Family: Family) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Family/SaveFamily`, {
      ...Family,
      idEntreprise: idCompany,
    });
  },
  saveFamily: (Family: Family) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Family/SaveFamily`, {
      ...Family,
      idEntreprise: idCompany,
    });
  },
  deleteFamily: (id: number, type: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Family/Delete${type == 'f' ? '' : 'Sub'}Family/${id}?idEntreprise=${idCompany}`,
    );
  },
  //TVA
  getTva: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/GeneralCodeTva?idEntreprise=${idCompany}`,
    );
  },
  postTva: (tva: GeneralCodeTva | undefined) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/GeneralCodeTva`, {
      ...tva,
      idEntreprise: idCompany,
    });
  },
  deleteTva: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/GeneralCodeTva/${id}?idEntreprise=${idCompany}`,
    );
  },
  //allegerne
  getAllergene: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Allergen?idEntreprise=${idCompany}`,
    );
  },
  getAllergeneByid: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Allergen/${id}?idEntreprise=${idCompany}`,
    );
  },
  postAllergene: (all: ArticleAllergen) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Allergen`, {
      ...all,
      idEntreprise: idCompany,
    });
  },
  deleteAllergene: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Allergen/${id}?idEntreprise=${idCompany}`,
    );
  },
  //Folders
  getFoldersOfFolder: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/UploadFolder/GetUploadFolderByIdWithInclude/${id}?idEntreprise=${idCompany}`,
    );
  },
  getAllFolders: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/UploadFolder?idEntreprise=${idCompany}`,
    );
  },
  addFolder: (folder: UploadFolder) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(
      `${config.api_baseUrl}/UploadFolder/SaveUploadFolder`,
      { ...folder, idEntreprise: idCompany },
    );
  },
  getFolderWithIncludes: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/GetUploadFolderWithInclude?idEntreprise=${idCompany}`,
    );
  },
  deleteFolder: (id: number, isSub?: boolean) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/UploadFolder/Delete${isSub ? 'Sub' : ''}UploadFolder/${id}?idEntreprise=${idCompany}`,
    );
  },
  getSubFolderOfFolder: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/GetUploadFolderById/${id}?idEntreprise=${idCompany}`,
    );
  },
  //Images
  getImagesOfFolder: (path: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/UploadImage?path=${path}&idEntreprise=${idCompany}`,
    );
  },

  postImageInFolder: (pathImage: string, idFolder: number, image: any) => {
    let idCompany = localStorage.getItem('idCompany');

    const formData = new FormData();
    formData.append('imageFile', image);
    return authUploadAxios.post(
      `${config.api_baseUrl}/UploadImage?pathImage=${pathImage}&idFolder=${idFolder}&idEntreprise=${idCompany}`,
      formData,
    );
  },
  deleteImage: (idImage: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/UploadImage/${idImage}?idEntreprise=${idCompany}`,
    );
  },
  //ARTICLE
  addArticle: ({
    article,
    articleCompositionIdArticleNavigations,
    articleAllergens,
    deletedIngredients,
    deletedAllergenes,
  }: addArticlePayload) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/v2/createArticle`, {
      article: {
        ...article,
        idEntreprise: idCompany,
        articleCompositionIdArticleNavigations: [
          ...removeDuplicates(articleCompositionIdArticleNavigations),
        ],
        articleAllergens: [...articleAllergens],
      },
      deletedIngredients,
      deletedAllergenes,
    });
  },
  getArticleList: (params?: getArticleParams) => {
    let idCompany = localStorage.getItem('idCompany');
    return authAxios.get(
      `${config.api_baseUrl}/v1/getArticlesByFamilySubFamily?idEntreprise=${idCompany}`,
      {
        params: {
          ...params,
        },
      },
    );
  },
  updateEtatDeStock: (params?: getArticleParams) => {
    let idCompany = localStorage.getItem('idCompany');
    let p = { ...params };
    delete p.etat_de_stock;
    delete p.articles;

    return authAxios.post(
      `${config.api_baseUrl}/v1/modifierEtatStockArticles?idEntreprise=${idCompany}`,
      {
        articles: params?.articles,
      },
      {
        params: p,
      },
    );
  },
  updateEtatDeStockMenus: (params?: {
    IdEstablishment: number;
    etat: number;
    menuIds: number[];
  }) => {
    let idCompany = localStorage.getItem('idCompany');
    let p = { ...params };
    delete p.menuIds;
    return authAxios.post(
      `${config.api_baseUrl}/v1/UpdateEtatStockMenu?idEntreprise=${idCompany}`,
      {
        menuIds: params?.menuIds,
      },
      {
        params: p,
      },
    );
  },
  getCategoriesEtatDeStock: (params?: {
    IdEstablishment: number;
    EtatStock?: number | undefined;
    nom?: string;
  }) => {
    let idCompany = localStorage.getItem('idCompany');
    let p = { ...params };
    if (p.EtatStock == undefined) {
      delete p.EtatStock;
    }
    return authAxios.get(
      `${config.api_baseUrl}/v1/GetCategoryStockStatus?idEntreprise=${idCompany}`,

      {
        params: p,
      },
    );
  },
  updateCategoriesEtatDeStock: (params?: {
    IdEstablishment: number;
    Visible: boolean;
    categoryIds: number[];
  }) => {
    let idCompany = localStorage.getItem('idCompany');
    let p = { ...params };
    delete p.categoryIds;
    return authAxios.post(
      `${config.api_baseUrl}/v1/UpdateEtatStockCategory?idEntreprise=${idCompany}`,
      {
        categoryIds: params?.categoryIds,
      },
      {
        params: p,
      },
    );
  },
  getListCompanies: () => {
    return authAxios.get(
      `${config.api_baseUrl}/v1/getListCompaniesFromEstablishementAccess`,
      {
        params: {
          module: 'Géneral.Affichage',
          claimType: 'Etablissement',
        },
      },
    );
  },
  getListPv: (idChaine?: number) => {
    let idCompany = idChaine ?? localStorage.getItem('idCompany');
    return authAxios.get(
      `${config.api_baseUrl}/v1/getListEstablishementsByCompanyId`,

      {
        params: {
          module: 'Géneral.Affichage',
          companyId: idCompany,
          claimType: 'Etablissement',
        },
      },
    );
  },
  getArticleListEtatDeStock: (params?: getArticleParams) => {
    let idCompany = localStorage.getItem('idCompany');
    let p = { ...params };
    delete p.etat_de_stock;
    return authAxios.get(
      `${config.api_baseUrl}/v1/GetArticlesStockStatusByFamilySubFamily?idEntreprise=${idCompany}`,
      {
        params: {
          ...p,
        },
      },
    );
  },
  getArticleWithId: (idArticle: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/v1/getDetailsArticleById?idArticle=${idArticle}&idEntreprise=${idCompany}`,
    );
  },
  deleteArticleWithId: (idArticle: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/v1/deleteArticle?idArticle=${idArticle}&idEntreprise=${idCompany}`,
    );
  },
  // SCREENS
  getPurshasesModes: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/PurshaseMode?idEntreprise=${idCompany}`,
    );
  },
  getScreens: (ExeptNotVisible?: boolean) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Screen?idEntreprise=${idCompany}&ExeptNotVisible=${ExeptNotVisible ?? false}`,
    );
  },
  DeleteScreen: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Screen/${id}?idEntreprise=${idCompany}`,
    );
  },
  postScreen: (screen: screen, deletedDivisions?: string[]) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(
      `${config.api_baseUrl}/Screen`,
      deletedDivisions && deletedDivisions?.length > 0
        ? {
            screen: { ...screen, idEntreprise: idCompany },
            deletedDivisions,
          }
        : {
            screen: { ...screen, idEntreprise: idCompany },
          },
    );
  },
  getScreenById: (id: number) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Screen/GetScreenById/${id}?idEntreprise=${idCompany}`,
    );
  },
  getAllLevels: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Level?idEntreprise=${idCompany}`,
    );
  },
  postLevels: (level: levelPayload) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Level`, {
      ...level,
      level: {
        ...level.level,
        idEntreprise: idCompany,
      },
    });
  },
  deleteLevel: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Level/${id}?idEntreprise=${idCompany}`,
    );
  },
  getLevelById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Level/GetLevelById/${id}?idEntreprise=${idCompany}`,
    );
  },
  //MENUS
  getAllMenusList: () => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Menu?idEntreprise=${idCompany}`,
    );
  },
  getAllMenusListStock: (params?: {
    IdEstablishment?: number;
    EtatStock?: number;
    nom?: string;
  }) => {
    const idCompany = localStorage.getItem('idCompany');
    const p = { ...params };

    if (p?.EtatStock != undefined && p.EtatStock == -1) {
      delete p?.EtatStock;
    }
    return authAxios.get(
      `${config.api_baseUrl}/v1/GetMenusStockStatus?idEntreprise=${idCompany}`,
      {
        params: {
          ...p,
          nom: p.nom || undefined,
        },
      },
    );
  },

  postMenu: (m: MenuPayload) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Menu`, {
      ...m,
      menu: {
        ...m.menu,
        idEntreprise: idCompany,
      },
    });
  },
  getMenuById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Menu/GetMenuById/${id}?idEntreprise=${idCompany}`,
    );
  },
  deleteMenuById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Menu/${id}?idEntreprise=${idCompany}`,
    );
  },
  //categories
  getCategoryList: (noEmpty?: boolean) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Category?idEntreprise=${idCompany}&EliminateEmpty=${noEmpty == true ? true : false}`,
    );
  },
  postCategory: (payload: CategoryPayLoad) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Category`, {
      ...payload,
      category: { ...payload.category, idEntreprise: idCompany },
    });
  },
  getCategoryById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Category/GetCategoryById/${id}?idEntreprise=${idCompany}`,
    );
  },
  deleteCategoryById: (id: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Category/${id}?idEntreprise=${idCompany}`,
    );
  },
  //cart
  postCart: (payload: carteConfig) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Card`, {
      ...payload,
      idEntreprise: idCompany,
    });
  },
  getAllCarts: (companyId?: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Card?idEntreprise=${companyId ?? idCompany}`,
    );
  },
  getListPvFiltred: (filtredPvConfig: filtredPvConfig) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/TypeMachine_PointOfSale/getListPointOfSaleTypeMachine?ActionTarget=${filtredPvConfig.actionTarget}&cardId=${filtredPvConfig.cardId}&ActionTargetId=${filtredPvConfig.actionTargetId}${
        filtredPvConfig.navigationId
          ? '&NavigationId=' + filtredPvConfig.navigationId
          : ''
      }&EntrepriseId=${idCompany}`,
    );
  },
  getListPvFiltredCard: (filtredPvConfig: filtredPvConfig) => {
    let idCompany = localStorage.getItem('idCompany');
    return authAxios.get(
      `${config.api_baseUrl}/TypeMachine_PointOfSale/GetPOSTypeMachinesCard?cardId=${filtredPvConfig.cardId}&EntrepriseId=${idCompany}`,
    );
  },
  getListMachinesCard: (filtredPvConfig: filtredPvConfig) => {
    let idCompany = localStorage.getItem('idCompany');
    return authAxios.get(
      `${config.api_baseUrl}/TypeMachine_PointOfSale/GetPOSTypeMachinesCard?cardId=${filtredPvConfig.cardId}&EntrepriseId=${idCompany}`,
    );
  },
  postAssignPVCard: (payload?: modalData) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(
      `${config.api_baseUrl}/TypeMachine_PointOfSale/AssignPOSTypeMachineCad`,
      { ...payload, idEntreprise: idCompany },
    );
  },
  postAssignPV: (payload?: modalData) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(
      `${config.api_baseUrl}/TypeMachine_PointOfSale/AssignPOSTypeMachine`,
      { ...payload, idEntreprise: idCompany },
    );
  },
  dragUnDragPV: (payload?: modalData) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.post(`${config.api_baseUrl}/Card/UpdateCardCategory`, {
      ...payload,
      idEntreprise: idCompany,
    });
  },
  getCardbyID: (id?: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.get(
      `${config.api_baseUrl}/Card/GetCardById/${id}?idEntreprise=${idCompany}`,
    );
  },
  deleteCard: (id?: string) => {
    let idCompany = localStorage.getItem('idCompany');

    return authAxios.delete(
      `${config.api_baseUrl}/Card/${id}?idEntreprise=${idCompany}`,
    );
  },
  updateCardCategoryOrderIndex: (
    category: { cardCategoryId: string; orderIndex: string }[],
  ) => {
    let idCompany = localStorage.getItem('idCompany');
    return authAxios.post(
      `${config.api_baseUrl}/Card/updateCardCategoryOrderIndex?idEntreprise=${idCompany}`,
      category,
    );
  },
};
