import React, { useState } from 'react';
import SwitcherCheckbox from '../../../../components/Catalogue/SwitcherCheckbox';
import InputField from '../../../../components/Input/Input';
import TimerSelect from './TimerSelect';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';

interface ModeLivraisonProps {
  title?: string;
  state: any;
  handleInputChange: any;
  handleSwitcherChange: any;
}

const ModeLivraison: React.FC<ModeLivraisonProps> = ({
  title,
  state,
  handleInputChange,
  handleSwitcherChange,
}) => {

  return (
    <div className="ModeLivraison tab-content-section">
      <div className="block-wrapper fl_col gp20">
        <div className="fl_row gp20">
          <SwitcherCheckbox
            ischecked={state?.supportsOnSpot}
            onChange={() => handleSwitcherChange('supportsOnSpot')}
            label="Sur place"
          />
          <SwitcherCheckbox
            ischecked={state?.supportsPickUp}
            onChange={() => handleSwitcherChange('supportsPickUp')}
            label="A Emporter"
          />
          <SwitcherCheckbox
            ischecked={state?.supportsDeliver}
            onChange={() => handleSwitcherChange('supportsDeliver')}
            label="En livraison"
          />
        </div>
        <div className="fl_col gp20">
          {(state?.supportsOnSpot || state?.supportsPickUp) && (
            <TimerSelect
              dropdown
              label="Temps moyen de récupération"
              value={state?.averageRecoveryTime}
              onChange={(e: any) =>
                handleInputChange('averageRecoveryTime', e.target.value)
              }
            />
          )}
          {state?.supportsDeliver && (
            <>
              <TimerSelect
                dropdown
                label="Temps moyen de Livraison"
                value={state?.averageDeliveryTime}
                onChange={(e: any) =>
                  handleInputChange('averageDeliveryTime', e.target.value)
                }
              />
              <InputField
                name="share_capital"
                type="number"
                label="Seuil de panier (Livraison)"
                style="form"
                value={state?.cartThreshold}
                onChange={(e: any) =>
                  handleInputChange('cartThreshold', e.target.value)
                }
              />
              <InputField
                name="share_capital"
                type="number"
                label="Rayon de livraison (Km)"
                style="form"
                value={state?.deliveryRadius}
                onChange={(e: any) =>
                  handleInputChange('deliveryRadius', e.target.value)
                }
              />
            </>
          )}
        </div>
      </div>
      {/* <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div> */}
    </div>
  );
};

export default ModeLivraison;
